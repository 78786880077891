import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faHouseUser } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { faRightLong } from '@fortawesome/free-solid-svg-icons'
import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { fa1, fa2, fa3 } from '@fortawesome/free-solid-svg-icons'
import { faForward } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'



/* add icons to the library */
library.add(faUserSecret,faComment,faHeart,faMoneyBillWave,faEnvelopeOpen,faMagnifyingGlass,faCaretDown,faCaretUp,faHouseUser, faPen, faBan, faHouse, faRightLong, faLeftLong, faDesktop, faMoneyBillTrendUp, faChartLine, fa1, fa2, fa3, faForward,faPlus)

createApp(App)
.use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
