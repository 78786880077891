<template>
  <div class="modal-bg">
    <div class="modal" ref="modal">
      <div v-if = isLoading>
        <h1>loading...</h1>
      </div>
      <div v-else class="main">
        <div class="title">
          {{ offer.name }}
        </div>
        <div class="offer">
          <div class="name">
            <span class="field"> Mission: </span> {{ offer.name }}
          </div>
          <div class="place">
            <span class="field"> Localisation: </span> {{ offer.place }}
          </div>
          <div class="description">
            <span class="field"> Description: </span> {{ offer.description }}
          </div>
          <div class="price">
            <span class="field"> Prix: </span> {{ offer.price }} €
          </div>
          <div class="dateCreation">
            <span class="field"> Date de création: </span> {{ offer.dateCreation }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue"
import { onClickOutside } from "@vueuse/core";
import axios from "axios";
import router from "@/router";

const route = useRoute()
const modal = ref(null)

let isLoading = ref(true)
let offer = ref(null)

onMounted(() => {
  getOffer()
})

onClickOutside(modal, () => {
  router.go(-1)
});

async function getOffer() {
  isLoading.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/offers/" + route.params.id)
  .then(resp => {
      offer.value = resp.data
      isLoading.value = false
  })
}

</script>

<style scoped lang="less">
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  
  background: rgba(15, 19, 21, 0.8);
  backdrop-filter: blur(1rem);

  .modal {
    position: absolute;
    width: 600px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: black;
    background-color: rgb(248, 249, 250);
    border-radius: 14px;
  }
}

.main {
  margin-top: 60px;

  .title {
    font-size: 27px;
    margin-bottom: 20px;
    color: black;
    font-weight: 600;
    text-align: center;
  }

  .offer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;

    padding: 22px;
    width: 400px;

    font-size: 20px;

    .field {
      font-weight: bold;
    }
  }
}

</style>

<style scoped>
@media screen and (max-device-width: 1000px) {
  .modal {
    width: 60vw;
  }
}
@media screen and (max-device-width: 600px) {
  .modal {
    width: 80vw;
  }
}
</style>