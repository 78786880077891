<template>
  <div class="main">
    <div class="title"> Poster une offre </div>
    <div class="form">
      <div class="txt_field">
        <input v-model="postOffer.name" type="text" required>
        <span :style="{'--lineColor': lineColor}"></span>
        <label>Nom de l'offre</label>
      </div>
      <div class="txt_field">
        <input v-model="postOffer.description" type="text" required>
        <span :style="{'--lineColor': lineColor}"></span>
        <label>Description</label>
      </div>
      <div class="txt_field">
        <input v-model="postOffer.place" type="text" required>
        <span :style="{'--lineColor': lineColor}"></span>
        <label>Lieu</label>
      </div>
      <div class="txt_field">
        <input v-model="postOffer.price" type="number" required>
        <span :style="{'--lineColor': lineColor}"></span>
        <label>Montant</label>
      </div>
      <div class="txt_field">
        <input v-model="postOffer.maxCandidateNumber" type="number" required>
        <span :style="{'--lineColor': lineColor}"></span>
        <label>Nombre maximum de candidats</label>
      </div>
      <div class="check_boxes">
        <label>Acceptation automatique</label>
        <input v-model="postOffer.automaticAccept" type="radio" value="true" name="radio">
        <label>Oui</label>
        <input v-model="postOffer.automaticAccept" type="radio" value="false" name="radio">
        <label>Non</label>
      </div>
      <div class="date_field">
        <label>Date de clôture (mois, jour, année)</label>
        <input id="date" 
        v-model="postOffer.dateFinish" 
        type="date"
        required>
      </div>
      <button @click="submit()">
        Envoyer
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import axios from 'axios';
import { store } from '@/store';
import router from "@/router";

const lineColor = ref("rgb(37, 212, 108)");
let postOffer = {}

onMounted(() => {
  if (store.userType != 'employer') {
    router.push('/')
  }
})

async function submit() {
  postOffer.dateFinish = postOffer.dateFinish.replace("T", " ")
  postOffer.automaticAccept = postOffer.automaticAccept == 'true' ? true : false
  // pour l'instant fake uuid
  postOffer.companyId = "4f172b49-9eb0-4a28-a348-4e01111a4cdf"
  await axios.post(process.env.VUE_APP_API_URL + "/offers", JSON.stringify(postOffer), {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    console.log(resp.status)
  })
  .catch(err => {
    console.log(err.response.status)
  })
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.main {
  .title {
    .titlePageStyle();
  }
  .form {
    width: 80vw;
    margin: 0 auto;
    margin-top: 30px;
    background: @bgColorLight;
    .lightBoxStyle();
    .formStyle();

    button {
      .formButtonStyle(30%);
      margin-top: 20px;
    }

    .date_field {
      display: flex;
      align-items: center;
      gap: 10px;

      input[type="date"] {
        font-size: 13px;
        padding: 5px;
        border: 2px solid #ccc;
        border-radius: 5px;
        background-color: #f9f9f9;
        color: #444;
        box-sizing: border-box;
      }

      input[type="date"]:focus {
        border-color: @primaryColor;
        outline: none;
      }
    }
  }
}
</style>