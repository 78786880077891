<template>
  <div v-if="isLoading"> is loading... </div>
  <div v-else class="main">
    <div class="title"> Votre compte </div>
    <div class="items">
      <div class="general-infos">
        <div class="second-title"> Infos générales du compte</div>
        <table>
          <tbody>
            <tr v-for="(data, key) in dataGeneralInfos">
              <td class="left">{{ data.trad }}:</td>
              <td class="right">
                <div class="value-modify" :class="{ visible : !dataGeneralInfos[key]['visible'] }">
                  <div class="value"> {{ data.value }} </div>
                  <div class="pen-icon" :class="{ hidden : dataGeneralInfos[key]['visible'] }" @click="modifyInputVisibility(key, 'general')"> 
                    <font-awesome-icon icon="fa-solid fa-pen" /> 
                  </div>
                </div>
                <div class="value-input" :class="{ visible : dataGeneralInfos[key]['visible'] }">
                  <input @input="inputTyping(key, 'general')" v-model="patchGeneral[key]" type="text">
                  <font-awesome-icon class="fa-icon" icon="fa-solid fa-ban" @click="modifyInputVisibility(key, 'general', 'close')"/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="button" :class="{ 'empty' : inputGenEmpty }" @click="submit('general')">modifier</div>
      </div>
      <div class="more-infos">
        <div class="second-title"> Bio et CV </div>
        <table>
          <tbody>
            <tr v-for="(data, key) in dataMoreInfos">
              <td class="left">{{ data.trad }}:</td>
              <td class="right">
                <div class="value-modify" :class="{ visible : !dataMoreInfos[key]['visible'] }">
                  <div class="value"> {{ data.value }} </div>
                  <div class="pen-icon" :class="{ hidden : dataMoreInfos[key]['visible'] }" @click="modifyInputVisibility(key, 'more')"> 
                    <font-awesome-icon icon="fa-solid fa-pen" /> 
                  </div>
                </div>
                <div class="value-input" :class="{ visible : dataMoreInfos[key]['visible'] }">
                  <input @input="inputTyping('more')" v-model="patchMore[key]" type="text">
                  <font-awesome-icon class="fa-icon" icon="fa-solid fa-ban" @click="modifyInputVisibility(key, 'more', 'close')"/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="button" :class="{ 'empty' : inputMorEmpty }" @click="submit('more')">modifier</div>
      </div>
      <div class="rib">
        <div class="second-title"> RIB </div>
        <table>
          <tbody>
            <tr v-for="(data, key) in dataRib">
              <td class="left">{{ data.trad }}:</td>
              <td class="right">
                <div class="value-modify" :class="{ visible : !dataRib[key]['visible'] }">
                  <div class="value"> {{ data.value }} </div>
                  <div class="pen-icon" :class="{ hidden : dataRib[key]['visible'] }" @click="modifyInputVisibility(key, 'rib')"> 
                    <font-awesome-icon icon="fa-solid fa-pen" /> 
                  </div>
                </div>
                <div class="value-input" :class="{ visible : dataRib[key]['visible'] }">
                  <input @input="inputTyping('rib')" v-model="patchRib[key]" type="text">
                  <font-awesome-icon class="fa-icon" icon="fa-solid fa-ban" @click="modifyInputVisibility(key, 'rib', 'close')"/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="button" :class="{ 'empty' : inputRibEmpty }" @click="submit('rib')">modifier</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { store } from '@/store';
import axios from "axios"
import { ref, onMounted } from "vue"
import router from '@/router';

let isLoading = ref(true)

let inputGenEmpty = ref(true)
let inputMorEmpty = ref(true)
let inputRibEmpty = ref(true)

let dataGeneralInfos = ref({
  type: {
    trad: "Type",
    value: null,
    visible: false
  },
  lastName: {
    trad: "Nom",
    value: null,
    visible: false
  },
  firstName: {
    trad: "Prénom",
    value: null,
    visible: false
  },
  age: {
    trad: "Age",
    value: null,
    visible: false
  },
  address: {
    trad: "Adresse",
    value: null,
    visible: false
  },
  zipCode: {
    trad: "Code postal",
    value: null,
    visible: false
  },
  region: {
    trad: "Région",
    value: null,
    visible: false
  },
  phoneNumber: {
    trad: "Numéro de téléphone",
    value: null,
    visible: false
  },
  email: {
    trad: "Email",
    value: null,
    visible: false
  }
})

let dataMoreInfos = ref({
  motivation: {
    trad: "Bio",
    value: null,
    visible: false
  },
  cv: {
    trad: "CV",
    value: null,
    visible: false
  }
})

let dataRib = ref({
  iban: {
    trad: "Iban",
    value: null,
    visible: false
  },
  bic: {
    trad: "Code bic",
    value: null,
    visible: false
  },
  establishment: {
    trad: "Code banque",
    value: null,
    visible: false
  },
  counter: {
    trad: "Code guichet",
    value: null,
    visible: false
  },
  accountNumber: {
    trad: "Numéro de compte",
    value: null,
    visible: false
  },
  ribKey: {
    trad: "Clé de RIB",
    value: null,
    visible: false
  },
  domiciliation: {
    trad: "Domiciliation",
    value: null,
    visible: false
  }
})

let user = ref(null)
let patchGeneral = {}
let patchMore = {}
let patchRib = {}

onMounted(() => {
  if (store.userId === null) {
    router.push('/connexion')
  } else {
    getUser()
  }
})

async function getUser() {
  isLoading.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/users/" + store.userId, {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    user.value = resp.data
    isLoading.value = false
    DTOtoData(user.value)
  })
}

function DTOtoData(user) {
  const keysGeneral = Object.keys(dataGeneralInfos.value)
  for (const key of keysGeneral) {
    if (user[key] != undefined) {
      dataGeneralInfos.value[key]['value'] = user[key]
    }
  }
  const keysMore = Object.keys(dataMoreInfos.value)
  for (const key of keysMore) {
    if (user[key] != undefined) {
      dataMoreInfos.value[key]['value'] = user[key]
    }
  }
  const keysRib = Object.keys(dataRib.value)
  for (const key of keysRib) {
    if (user['rib'][key] != undefined) {
      dataRib.value[key]['value'] = user['rib'][key]
    }
  }
}

function modifyInputVisibility(key, divType, cursorType) {
  if (cursorType == 'close' && divType == 'general') {
    delete patchGeneral[key]
    if (Object.keys(patchGeneral).length == 0) {
      inputGenEmpty.value = true
    }
  } else if (cursorType == 'close' && divType == 'more') {
    delete patchMore[key]
    if (Object.keys(patchMore).length == 0) {
      inputMorEmpty.value = true
    }
  } else if (cursorType == 'close' && divType == 'rib') {
    delete patchRib[key]
    if (Object.keys(patchRib).length == 0) {
      inputRibEmpty.value = true
    }
  }
  if (divType == 'general') {
    dataGeneralInfos.value[key].visible = !dataGeneralInfos.value[key].visible
  } else if (divType == 'more') {
    dataMoreInfos.value[key].visible = !dataMoreInfos.value[key].visible
  } else if (divType == 'rib') {
    dataRib.value[key].visible = !dataRib.value[key].visible
  }
}

function inputTyping(key) {
  const genKeys = Object.keys(patchGeneral)
  const moreKeys = Object.keys(patchMore)
  const ribKeys = Object.keys(patchRib)
  inputGenEmpty.value = inputMorEmpty.value = inputRibEmpty.value = true
  genKeys.forEach(key => {
    if (patchGeneral[key] != '') {
      inputGenEmpty.value = false
    }
  });
  moreKeys.forEach(key => {
    if (patchMore[key] != '') {
      inputMorEmpty.value = false
    }
  });
  ribKeys.forEach(key => {
    if (patchRib[key] != '') {
      inputRibEmpty.value = false
    }
  });
}

async function submit(divType) {
  let obj = {}
  if (divType == 'general') {
    if (inputGenEmpty.value) {
      return
    }
    obj = patchGeneral
  }
  else if (divType == 'more') {
    if (inputMorEmpty.value) {
      return
    }
    obj = patchMore
  }
  else if (divType == 'rib') {
    if (inputRibEmpty.value) {
      return
    }
    patchRib.id = user.value.rib.id
    obj = {
      rib: patchRib
    }
  }
  await axios.patch(process.env.VUE_APP_API_URL + "/users/" + store.userId, JSON.stringify(obj), {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    console.log(resp.status)
    getUser()
  })
  .catch(error => {
    console.log(error.response.status)
  })
}

</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.main {
  .title {
    .titlePageStyle();
  }

  .items {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 50px;

    .general-infos, .more-infos, .rib {
      .lightBoxStyle();
      padding: 22px;
      width: 50%;

      display: flex;
      flex-direction: column;
      gap: 15px;

      .second-title {
        margin-bottom: 30px;
        color: @secondaryColor;
        font-size: 22px;
      }

      table {
        margin: 0 auto;
        width: 70%;
        text-align: left;
        td {
          padding: 5px 10px;
          &.left {
            width: 50%;
          }
          &.right {
            transform: translateX(30%);
          }
          .value-modify {
            position: absolute;
            display: flex;
            gap: 5px;
            visibility: hidden;
            &.visible {
              visibility: visible;
            }
            .pen-icon {
              visibility: hidden;
              cursor: pointer;
              color: @secondaryColorLighter;
            }
            &:hover {
              .pen-icon {
                visibility: visible;
                &.hidden {
                  visibility: hidden;
                  cursor: default;
                }
              }
            }
          }
          .value-input {
            visibility: hidden;
            display: flex;
            align-items: center;
            gap: 5px;
            .fa-icon {
              color: @secondaryColor;
              cursor: pointer;
            }
            &.visible {
              visibility: visible;
            }
          }
        }
      }

      .button {
        .buttonStyle(@primaryColor);
        width: 20%;
        &.empty {
          background-color: @primaryColorGrey;
          cursor: default;
        }
      }
    }
  }
}
</style>