<template>
  <div class="chat">
    <div class="title">
      Chat
    </div>
    <div class="items">
      <div class="chats">
        <div v-if="store.userType === 'candidate'" class="chats-title">
            Contacter vos employeurs
        </div>
        <div v-else class="chats-title">
            Contacter vos candidats
        </div>

        <div v-if="isChatListLoading" class="spinner">
          <Spinner color="black"/>
        </div>
        <div v-else class="friends-list" v-for="chatItem in chatList">
          <div class="listFriend" @click="loadChatWs(chatItem.id, chatItem.otherUserInfos.lastName, chatItem.otherUserInfos.firstName, chatItem.user2Id)">
            <div class="friend-name">
              <img class="img" src="https://e7.pngegg.com/pngimages/522/396/png-clipart-computer-icons-profile-miscellaneous-logo.png">
              <div class="lastName"> {{ chatItem.otherUserInfos.lastName }} </div>
              <div class="firstName"> {{ chatItem.otherUserInfos.firstName }} </div>
              <div v-if="chatItem.nonRead !== 0" class="nonRead"> {{ chatItem.nonRead }} </div>
              <div class="connected">[IF_CONNECTED]</div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat">
        <div v-if="globalChatId === ''" class="no-chat-selected">Sélectionner un ami</div>
        <div v-else class="chat-selected">
          <div v-if="isChatLoading" class="spinner">
            <Spinner color="black"/>
          </div>
          <div v-else-if="errorWs">
            Error
          </div>
          <div v-else>
            <div class="chat-title">
              Vous êtes en conversation avec : {{ chat }}
            </div>
            <div v-if="messages.length === 0">
              Pas de messages
            </div>
            <div v-else class="chat-content">
              <div v-for="msg in messages" :class="{ mine: msg?.userId == store.userId }">
                {{ msg.message }}
              </div>
            </div>
            <div class="chat-input">
              <input v-model="postMessage" placeholder="Entrer message..." type="text"
              @keyup.enter="sendMessage()">
              <input type="submit" value="Envoyer" @click="sendMessage()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Spinner from "@/components/Spinner.vue"
import { store } from '@/store';
import router from '@/router';
import axios from "axios"

let chatWs
let notifWs

let isChatLoading = ref(true)
let isChatListLoading = ref(true)

let errorWs = ref(false)

let globalChatId = ref("")
let chat = ref("")
let chatList = ref([])
let postMessage = ref("")
let messages = ref([])

onMounted(async() => {
  if (store.userId === null) {
    router.push('/connexion')
  }
  await getChats()
  loadNotifWs()
})

async function loadNotifWs() {
  isChatListLoading.value = true

  if (notifWs != null) {
    notifWs.close()
  }
  notifWs = new WebSocket(process.env.VUE_APP_WS_URL + "/notifs")
  notifWs.onopen = () => {
    notifWs.send(JSON.stringify(
      {
        "token": store.token,
      }
    ))
    isChatListLoading.value = false
  }
  notifWs.onerror = () => {
    isChatListLoading.value = true
  }
  notifWs.onmessage = (notif) => {
    const notifData = JSON.parse(notif.data)
    const chat = chatList.value.find(c => c.id === notifData.chatId)
    if (chat) {
      if (globalChatId.value === notifData.chatId) {
        chat.nonRead = 0
      } else {
        if (!chat.hasOwnProperty('nonRead')) {
          chat.nonRead = 1
        } else {
          chat.nonRead += 1
        }
      }
    }
  }
}

async function getChats() {
  isChatListLoading.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/chats", {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    chatList.value = resp.data
    isChatListLoading.value = false
  })
}

async function loadChatWs(chatId, lastName, firstName, otherUser) {
  isChatLoading.value = true
  globalChatId.value = chatId
  if (chatWs != null) {
    chatWs.close()
  }
  chatWs = new WebSocket(process.env.VUE_APP_WS_URL + `/chats/${chatId}`)

  chatWs.onopen = () => {
    chatWs.send(JSON.stringify(
      {
        "token": store.token,
        "otherUser": otherUser
      }
    ))
    chat.value = lastName + " " + firstName
    isChatLoading.value = false
    const chatNotif = chatList.value.find(c => c.id === chatId)
    if (chatNotif) {
      chatNotif.nonRead = 0
    }
  }
  chatWs.onerror = () => {
    errorWs.value = true
  }
  chatWs.onmessage = (msg) => {
    let msgData = JSON.parse(msg.data)
    if (Array.isArray(msgData)) {
      messages.value = msgData
    } else if (msgData === null) {
      messages.value = []
    }
    else {
      messages.value.push(msgData)
    }
  }
}

function sendMessage() {
  let msg = {
    "chatId": globalChatId.value,
    "message": postMessage.value
  }
  chatWs.send(JSON.stringify(msg))
  postMessage.value = ""
}
</script>


<style scoped lang="less">
@import "@/shared/theme.less";
.chat {
  .title {
    .titlePageStyle();
  }
  .items {
    border: solid red;
    display: flex;
    gap: 20px;
    padding: 0 20px;
    margin-bottom: 50px;
    .chats {
      border: solid blue;

      .chats-title {
        margin-bottom: 20px;
      }
      .img{
        width: 50px;
        border-radius: 50px;
      }
      .listFriend{
        display: block;
        .friend-name {
          display: flex;
          gap: 10px;
          cursor: pointer;
          &:hover {
            background: aquamarine;
          }
        }
        .connected {
          position: absolute;
          margin-left: 60px;
          margin-top: 20px;
        }
      }
    }
    .chat {
      border: solid greenyellow;
      flex-grow: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      .chat-selected {
        width: 100%;
        height: 100%;
      }
      .chat-title {
        margin-bottom: 20px;
      }
      .chat-content {
        text-align: left;
        .mine {
          text-align: right;
        }
      }
    }
  }
}
</style>