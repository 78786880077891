<template>
  <section class="listOffre">
    <div class="blockOffres">
      <div class="rightDIV">
        <h1 v-if="listeOffres.length <=1 "><b>{{ listeOffres.length }}</b> offre d'emploi</h1>
        <h1 v-else><b>{{ listeOffres.length }}</b> offres d'emploi</h1>
        <p class="textSearch">Correspondant à votre recherche !</p>
        <div class="element">
          <input type="reset" value="Reinitialiser les filtres">
        </div>

        <br>

        <div class="divDropdown">
            <div @click="drop1 = !drop1" class="titlesDropdown">
              <h3 class="titleDropdown">Contrats</h3>
              <p v-if="drop1" class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-up" /></p>
              <p v-else class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-down" /></p>
            </div>
            <transition name="fade">
              <div v-if="drop1" class="elementDropdown">
                <div class="element">
                  <div v-for="item in listContrats" :key="item.name">
                    <div  class="round">
                      <input type="checkbox" :id=item.name />
                      <label :for=item.name></label>
                      <p class="lblCheckbox">{{ item.name }}</p>
                    </div>
                  </div>

                </div>
              </div>
            </transition>
          </div>

        <div class="divDropdown">
          <div @click="drop2 = !drop2" class="titlesDropdown">
            <h3 class="titleDropdown">Salaire minimum</h3>
            <p v-if="drop2" class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-up" /></p>
            <p v-else class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-down" /></p>
          </div>
          <transition name="fade">
            <div v-if="drop2" class="elementDropdown">
              <div class="element">
                <p>Modifier à la valeur minimum souhaité</p>
                <input type="range">
                <div class="divEstimation">
                  <div class="estimation">
                    <p class="left">Temps de mission</p>
                    <p class="right">335,40 €</p>
                  </div>
                  <div class="estimation">
                    <p class="left">Journalié</p>
                    <p class="right">35,40 €</p>
                  </div>
                  <div class="estimation">
                    <p class="left">Horaire</p>
                    <p class="right">5,40 €</p>
                  </div>
                  <div class="estimation">
                    <p class="test">Estimation : Date de fin de l'offre</p>
                  </div>
                </div>
              </div>

            </div>
          </transition>
        </div>

        <div class="divDropdown">
          <div @click="drop3 = !drop3" class="titlesDropdown">
            <h3 class="titleDropdown">Selectionner une date </h3>
            <p v-if="drop3" class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-up" /></p>
            <p v-else class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-down" /></p>
          </div>
          <transition name="fade">
            <div v-if="drop3" class="elementDropdown">

              <VueDatePicker
                  v-model="selectedDates"
                  :enable-time-picker="false"
                  show-now-button
                  now-button-label="Aujourd'hui"
                  select-text="Valider"
                  cancel-text="Annuler"
                  range
                  :dark="false"
                  locale="fr-FR"
                  :min-date="Date.now()"
              />

            </div>
          </transition>
        </div>

        <div class="divDropdown">
          <div @click="drop4 = !drop4" class="titlesDropdown">
            <h3 class="titleDropdown">Secteurs</h3>
            <p v-if="drop4" class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-up" /></p>
            <p v-else class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-down" /></p>
          </div>
          <transition name="fade">
            <div v-if="drop4" class="elementDropdown">
              <div class="element">
                <div v-for="item in listSecteurs" :key="item.name">
                  <div  class="round">
                    <input type="checkbox" :id=item.name />
                    <label :for=item.name></label>
                    <p class="lblCheckbox">{{ item.name }}</p>
                  </div>
                </div>

              </div>
            </div>
          </transition>
        </div>

        <div class="divDropdown">
          <div @click="drop5 = !drop5" class="titlesDropdown">
            <h3 class="titleDropdown">Type d'entreprise</h3>
            <p v-if="drop5" class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-up" /></p>
            <p v-else class="iconDropdown"><font-awesome-icon icon="fa-solid fa-caret-down" /></p>
          </div>
          <transition name="fade">
            <div v-if="drop5" class="elementDropdown">
              <div class="element">
                <div v-for="item in listTypeEntreprise" :key="item.name">
                  <div  class="round">
                    <input type="checkbox" :id=item.name />
                    <label :for=item.name></label>
                    <p class="lblCheckbox">{{ item.name }}</p>
                  </div>
                </div>

              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="leftDIV">

        <div v-if = isLoading>
          <h1>loading...</h1>
        </div>
        <div v-else class="offers">
          <div v-for="(offer, index) in offers" :key="index" class="block">
            <div class="sec1">
              <img src="https://st2.depositphotos.com/6489488/9371/v/450/depositphotos_93711112-stock-illustration-g-letter-logo-icon.jpg">
            </div>
            <div class="sec2">
              <p class="nameCompany">[NOM ENTREPRISE]</p>
              <p class="titleCompany">{{ offer.name }}</p>
              <div class="critCompany">

                <div class="critCompanyComponent">
                  <p class="icon"><font-awesome-icon icon="fa-solid fa-heart" class="primaryColor"/></p>
                  <p class="text">[TYPE]</p>
                </div>

                <div class="critCompanyComponent">
                  <p class="icon"><font-awesome-icon icon="fa-solid fa-heart" class="primaryColor"/></p>
                  <p class="text">{{ offer.place }}</p>
                </div>

                <div class="critCompanyComponent">
                  <p class="icon"><font-awesome-icon icon="fa-solid fa-money-bill-wave" class="primaryColor"/></p>
                  <p class="text">{{ offer.price }}</p>
                </div>
              </div>
              <div class="dateCompany"><p class="Archive">Disponibile</p>&nbsp;du&nbsp;<b>{{ offer.dateCreation }}</b>&nbsp;au&nbsp;<b>{{ offer.dateFinish }}</b></div>
            </div>
            <div class="sec3">
              <div class="field btn">
                <div class="btn-layer"></div>
                <input type="submit" value="Voir l'offre" @click="router().push('/Offre/' + offer.id)">
              </div>

              <div class="textPrice">
                <p class="nameCompany">{{ offer.price }} €/J NET</p>
              </div>

              <div class="icon" >
                <a href="#"><font-awesome-icon icon="fa-solid fa-heart" class="primaryColor"/></a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { store } from "@/store";
import axios from "axios"
import { ref, onMounted } from "vue"
import Apply from "@/components/Apply.vue";
import router from "@/router";

export default {
  methods: {
    router() {
      return router
    }
  },
  setup() {

    let isLoading = ref(true)
    let isApplyOpen = ref(false)
    let thisOfferId = ""
    let offers = ref([])

    onMounted(() => {
      getOffers()
    })

    function formattedDate(dateHF) {
      return new Intl.DateTimeFormat("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      }).format(dateHF);
    }



    function openApply(offerId) {
      isApplyOpen.value = true;
      thisOfferId = offerId
    }

    function openOffer(offerId) {
      router.push('/offres/offre/' + offerId)
    }

    function closeApply() {
      isApplyOpen.value = false;
    }

    async function getOffers() {
      isLoading.value = true
      await axios.get(process.env.VUE_APP_API_URL + "/offers")
          .then(resp => {
            offers.value = resp.data
            isLoading.value = false
          })
    }

    const selectedDates = ref();

    return {
      selectedDates,
      isLoading,
      offers,
    }
  },
  data() {

    return {
      selectedDate: null,
      openCalendar: false,
      drop1: true,
      drop2: true,
      drop3: false,
      drop4: false,
      drop5: false,
      drop6: false,
      listeOffres: [
        {
          id: 1,
          img: 'https://static9.depositphotos.com/1554103/1188/v/600/depositphotos_11884975-stock-illustration-company-business-logo-design-vector.jpg',
          nameCompany: "Name company",
          titleCompany: 'Titre de l\'OFFRE',
          critType: 'BTP',
          critLoc: 'Clermont-Ferrand - 63',
          price: 150,
          dateStart: '01/01/2023',
          dateEnd: '02/03/2023'
        },
        {
          id: 2,
          img: 'https://st2.depositphotos.com/6489488/9371/v/450/depositphotos_93711112-stock-illustration-g-letter-logo-icon.jpg',
          nameCompany: 'Name company',
          titleCompany: 'Titre de l\'OFFRE',
          critType: 'BTP',
          critLoc: 'Clermont-Ferrand - 63',
          price: 100,
          dateStart: '01/01/2023',
          dateEnd: '02/03/2023'
        },
        {
          id: 3,
          img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQupblzk7ZG440WdE5Vr_3SkAQgVcP5yuwtQA&usqp=CAU',
          nameCompany: 'Name company',
          titleCompany: 'Titre de l\'OFFRE',
          critType: 'BTP',
          critLoc: 'Clermont-Ferrand - 63',
          price: 75,
          dateStart: '01/01/2023',
          dateEnd: '02/03/2023'
        },
        {
          id: 4,
          img: 'https://static9.depositphotos.com/1554103/1188/v/600/depositphotos_11884975-stock-illustration-company-business-logo-design-vector.jpg',
          nameCompany: "Name company",
          titleCompany: 'Titre de l\'OFFRE',
          critType: 'BTP',
          critLoc: 'Clermont-Ferrand - 63',
          price: 90,
          dateStart: '01/01/2023',
          dateEnd: '02/03/2023'
        },
        {
          id: 5,
          img: 'https://st2.depositphotos.com/6489488/9371/v/450/depositphotos_93711112-stock-illustration-g-letter-logo-icon.jpg',
          nameCompany: 'Name company',
          titleCompany: 'Titre de l\'OFFRE',
          critType: 'BTP',
          critLoc: 'Clermont-Ferrand - 63',
          price: 85,
          dateStart: '01/01/2023',
          dateEnd: '02/03/2023'
        },
        {
          id: 6,
          img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQupblzk7ZG440WdE5Vr_3SkAQgVcP5yuwtQA&usqp=CAU',
          nameCompany: 'Name company',
          titleCompany: 'Titre de l\'OFFRE',
          critType: 'BTP',
          critLoc: 'Clermont-Ferrand - 63',
          price: 135,
          dateStart: '01/01/2023',
          dateEnd: '02/03/2023'
        },
      ],
      listContrats: [
        {
          name: 'Intérim',
        },
        {
          name: 'Indépendant • Freelance',
        }
      ],
      listSecteurs: [
        {
          name: 'BTP',
        },
        {
          name: 'Immobilier',
        },
        {
          name: 'Enseignement • Formation',
        },
        {
          name: 'Industrie Manufacturière',
        },
        {
          name: 'Restauration',
        },
      ],
      listTypeEntreprise: [
        {
          name: 'ESN',
        },
        {
          name: 'Entreprises',
        },
        {
          name: 'Travail temporaire',
        }
      ],
    }
  },

  components: {
    VueDatePicker
  },
}
</script>


<style scoped lang="less">
@import "@/shared/theme.less";

//calendar
.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: @primaryColor;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: @primaryColor;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}

.dp__theme_dark {
  --dp-background-color: #212121;
  --dp-text-color: #ffffff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #ffffff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #005cb2;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: #2d2d2d;
  --dp-menu-border-color: #2d2d2d;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}

// TODO : ANIM FONCTION SOURIS LOGO ENTREPRISE

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.primaryColor {
  color: @primaryColor;
}

.listOffre {
  //border: solid black;
  position: relative;
  margin-right: 15%;
  margin-left: 15%;
  font-family: 'Roboto', sans-serif;
  margin-top: 50px;
}

.blockOffres {
  //border: solid red;
  display: flex;
  justify-content: center;
}

.rightDIV {
  //border: solid forestgreen;
  width: 30%;
  height: 100%;
  padding-bottom: 40px;
}

.rightDIV .textSearch {
  font-size: 20px;
  padding-bottom: 20px;
}

.rightDIV .divDropdown {
  //border: solid black;
  margin-left: 5%;
}

.rightDIV .divDropdown .titlesDropdown {
  //border: solid greenyellow;
  display: flex;
}

.rightDIV .divDropdown .titlesDropdown .titleDropdown {
  font-size: 20px;
  //border: solid red;
}

.rightDIV .divDropdown .titlesDropdown .iconDropdown {
  //border: solid blue;
  font-size: 20px;
  padding-left: 5px;
}

.rightDIV .element input[type="reset"]{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  border: none;
  background-color: @primaryColor;
  color: #fff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 5px;
}

.rightDIV .element input[type="reset"]:hover{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  border: none;
  background-color: @primaryColorLighter;
  color: #fff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 5px;
}

.leftDIV {
  //border: solid blue;
  margin-left: 2%;
  width: 68%;
  height: 100%;
}

.element {
  text-align: left;
  margin-left: 5%;
}

.element .divEstimation {
  margin-top: 15px;
}

.element .estimation {
  color: @primaryColorGrey;
  display: flex;
  align-items: center;
  height: 30px;
}

.element .estimation .left {
  width: 70%;
}

.element .estimation .right {
  text-align: right;
  width: 30%;
 }

.element h3 {
  margin-left: 20px;
}

.element input {
  width: 90%;
}

//.element input[type="range"] {
//  cursor: pointer;
//  background-color: @primaryColor;
//}

.element input[type=range] {
  height: 34px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background-color: transparent;
}
.element input[type=range]:focus {
  outline: none;
}
.element input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: @primaryColor;
  border-radius: 5px;
  border: 0px solid #000000;
}
.element input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 2px #000000;
  border: 1px solid #000000;
  height: 27px;
  width: 27px;
  border-radius: 27px;
  background: #F8F9FA;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9px;
}
.element input[type=range]:focus::-webkit-slider-runnable-track {
  background: @primaryColor;
}
.element input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: @primaryColor;
  border-radius: 5px;
  border: 0px solid #000000;
}
.element input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 2px #000000;
  border: 1px solid #000000;
  height: 27px;
  width: 27px;
  border-radius: 27px;
  cursor: pointer;
}
.element input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.element input[type=range]::-ms-fill-lower {
  background: @primaryColor;
  border: 0px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
.element input[type=range]::-ms-fill-upper {
  background: @primaryColor;
  border: 0px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
.element input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 2px #000000;
  border: 1px solid #000000;
  height: 27px;
  width: 27px;
  border-radius: 27px;
  cursor: pointer;
}
.element input[type=range]:focus::-ms-fill-lower {
  background: @primaryColor;
}
.element input[type=range]:focus::-ms-fill-upper {
  background: @primaryColor;
}

.element .round {
  //border: solid blue;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.element .round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  width: 22px;
}

.element .round .lblCheckbox {
  position: absolute;
  //border: solid black;
  text-align: left;
  font-size: 16px;
  margin-left: 35px;
}

.element .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.element .round input[type="checkbox"] {
  visibility: hidden;
}

.element .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.element .round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.block {
  //border-radius: 10px;
  display: flex;
  justify-content: center;
  //border: solid forestgreen;
  margin: 5% 5%;
}

.block:hover {
  //background-color: #F8F9FA;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  margin: 5% 5%;
  -webkit-box-shadow: -10px 0 13px -7px #000000, 10px 0px 13px -7px #000000, 11px 3px 10px 5px rgba(0,0,0,0);
  box-shadow: -10px 0 13px -7px #000000, 10px 0px 13px -7px #000000, 11px 3px 10px 5px rgba(0,0,0,0);
}

.block .sec1 {
  //border: solid purple;
  width: 20%;
  margin: 2% 2%;
}
.block .sec2 {
  text-align: left;
  padding-left: 5px;
  //border: solid black;
  width: 62%;
}
.block .sec3 {
  //border: solid orangered;
  width: 18%;
  margin: 2% 2%;
}

.block .sec3 .icon {
  font-size: 27px;
  //margin-top: 47%;
  text-align: right;
  //border: solid black;
}

.block .sec1 img {
  font-size: 50px;
  border-radius: 25% 10%;
  width: 100%;
  //border: solid black;
}

.block .sec2 .nameCompany {
  position: relative;
  padding-top: 10px;
  font-size: 18px;
}

.block .sec2 .titleCompany {
  font-family: Archive,serif;
  text-overflow: ellipsis;
  font-size: 25px;
}

.block .sec2 .critCompany {
  display: flex;
  justify-content: left;
}

.block .sec2 .critCompanyComponent {
  border: solid 1px @primaryColor;
  border-radius: 17px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin-right: 10px;
  height: 28px;
}

.block .sec2 .critCompanyComponent:hover {
  border: solid 1px #F8F9FA;
  border-radius: 17px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin-right: 10px;
  height: 25px;
  -webkit-box-shadow: -10px 0 13px -7px #000000, 10px 0px 13px -7px #000000, 11px 3px 10px 13px rgba(0,0,0,0);
  box-shadow: -10px 0 13px -7px #000000, 10px 0px 13px -7px #000000, 11px 3px 10px 13px rgba(0,0,0,0);
}

.block .sec2 .critCompanyComponent .icon {
  margin-top: 5px;
  margin-right: 5px;
}

.block .sec2 .critCompanyComponent .text {
  margin-top: 5px;
  font-size: 15px;
}

.block .sec2 .dateCompany {
  display: flex;
  align-items: center;
}

.block .sec3 .textPrice {
  font-size: 20px;
  font-family: Archive, serif;
}

.block .sec3 .btn{
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.block .sec3 .btn .btn-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right,#F8F9FA,@primaryColor, #F8F9FA);
  border-radius: 15px;
  transition: all 0.4s ease;;
}
.block .sec3 .btn:hover .btn-layer{
  left: 0;
}
.block .sec3 .btn input[type="submit"]{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

</style>