<template>
  <section class="listOffre">
    <div class="blockOffres">
      <div class="rightDIV">
        <img src="https://e7.pngegg.com/pngimages/522/396/png-clipart-computer-icons-profile-miscellaneous-logo.png">
        <h1>{{ store.userName }}</h1>
        <p class="textSearch">Salut maldive ?</p>
        <div class="btn" @click="page = 'dashboard'">
          <font-awesome-icon icon="fa-solid fa-house" class="fontAwe"/>
          <a class="text" >Mes Informations</a>
        </div>

        <div v-if="store.userType === 'candidate'" class="btn" @click="page = ''">
          <font-awesome-icon icon="fa-solid fa-house" class="fontAwe"/>
          <a class="text">Mes Candidatures</a>
        </div>

        <div v-if="store.userType === 'employer'" class="btn" @click="page = ''">
          <font-awesome-icon icon="fa-solid fa-house" class="fontAwe"/>
          <a class="text">Mes Offres</a>
        </div>

        <div class="btn" @click="page = ''">
          <font-awesome-icon icon="fa-solid fa-house" class="fontAwe"/>
          <a class="text">SAlut</a>
        </div>

        <div class="btn" @click="page = 'chat'">
          <font-awesome-icon icon="fa-solid fa-house" class="fontAwe"/>
          <a class="text">Messagerie</a>
          <font-awesome-icon icon="fa-solid fa-comment" class="fontAweMess" v-if="nbMessage !== 0" />
          <a class="nbMessage" v-if="nbMessage !== 0" >{{nbMessage}}</a>
        </div>
      </div>
      <div class="leftDIV">
        <Dashboard v-if="page==='dashboard'"/>
        <Chat v-if="page==='chat'"/>
      </div>
    </div>
  </section>
</template>

<script setup>

import Dashboard from "./Dashboard.vue"
import Chat from "@/views/Profil/Sections/Chat.vue";
import {store} from "../../../store";

</script>

<script>

export default {
  data() {
    return {
      nbMessage: 2,
      page: 'dashboard',
    }
  }
}

</script>


<style scoped lang="less">
@import "@/shared/theme.less";

.primaryColor {
  color: @primaryColor;
}

.listOffre {
  //border: solid black;
  position: relative;
  margin-right: 15%;
  margin-left: 15%;
  font-family: 'Roboto', sans-serif;
  margin-top: 50px;
}

.blockOffres {
  //border: solid red;
  display: flex;
  justify-content: center;
}

.rightDIV {
  border: solid forestgreen;
  position: sticky;
  top: 40px;
  width: 30%;
  height: 100%;
  padding-bottom: 40px;
}

.rightDIV .textSearch {
  font-size: 20px;
  padding-bottom: 20px;
}

.rightDIV img {
  padding-top: 20px;
  width: 50%;
  border-radius: 50%;
}

.rightDIV .btn {
   text-align: center;
   margin-left: 5%;
   margin-right: 5%;
   background-color: @primaryColor;
   color: white;
   border-radius: 10px;
   padding: 2% 2%;
   margin-bottom: 5%;
 }

.rightDIV .btn:hover {
  background-color: @primaryColorLighter;
}

.rightDIV .btn .fontAwe {
  color: white;
  margin-bottom: 2px;
}

.rightDIV .btn .fontAweMess {
  //border: solid black;
  color: white;
  position: absolute;
  height: 25px;
  padding-bottom: 15px;
}

.rightDIV .btn .nbMessage {
  position: relative;
  color: black;
  z-index: inherit;
  font-family: 'Archive', sans-serif;
  margin-left: 8px;
}

.rightDIV .btn .text {
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  font-size: 20px;
  font-family: Archive, serif;
}

.leftDIV {
  border: solid blue;
  margin-left: 2%;
  width: 68%;
  height: 100%;
}



</style>