<template>
  <section class="secInscri">
  <div class="divInscri">
  <div class="main">
    <div class="title">
      Inscription
    </div>
    <div class="forms">
      <div class="form">
        <div class="check_boxes">
            <input v-model="user.type" type="radio" value="employer" id="employer" name="radio">
            <label for="employer">Employeur</label>
            <input v-model="user.type" type="radio" value="candidate" id="candidate" name="radio">
            <label for="candidate">Candidat</label>
        </div>
        <div class="txt_field name">
          <input v-model="user.lastName" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Nom</label>
        </div>
        <div class="txt_field">
          <input v-model="user.firstName" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Prénom</label>
        </div>
        <div class="txt_field">
          <input v-model="user.age" type="number" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Age</label>
        </div>
        <div class="txt_field">
          <input v-model="user.address" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Adresse</label>
        </div>
        <div class="txt_field">
          <input v-model="user.zipCode" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Code postal</label>
        </div>
      </div>
      <div class="form">
        <div class="txt_field">
          <input v-model="user.region" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Région</label>
        </div>
        <div class="txt_field">
          <input v-model="user.phoneNumber" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Numéro de téléphone</label>
        </div>
        <div class="txt_field">
          <input v-model="user.email" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label v-if="!conflict">Email</label>
          <label v-if="conflict" style="color: orange;">Email déjà pris</label>
        </div>
        <div class="txt_field">
          <input v-model="user.password" type="password" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label>Password</label>
        </div>
      </div>
    </div>
    <button @click="submit" :class="{ created: created }">
      <div v-if="!created && !inCreation">Créer le compte</div>
      <div v-if="inCreation"><Spinner color="white"/></div>
      <div v-if="created" style="color: green;">Compte créé !</div>
    </button>
  </div>
  </div>
  </section>
</template>

<script setup>
import { ref } from "vue"
import axios from "axios";
import Spinner from "../components/Spinner.vue"
import router from "@/router";

const lineColor = ref("rgb(37, 212, 108)");
let created = ref(false);
let inCreation = ref(false);
let conflict = ref(false);

let user = {
  rib: {}
};

async function submit() {
    inCreation.value = true;
    await axios.post(process.env.VUE_APP_API_URL + "/signup", JSON.stringify(user))
    .then(resp => {
      if (resp.status == 201) {
        inCreation.value = false;
        conflict.value = false;
        created.value = true;

        setTimeout(() => {
          created.value = false;
          router.push('/connexion')
        }, 2000)
      }
    })
    .catch(error => {
      if (error.response.status == 409) {
        inCreation.value = false;
        conflict.value = true;
        lineColor.value = "orange";
        setTimeout(() => {
          conflict.value = false;
        }, 3000)
      }
    })
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";


.secInscri {
  position:relative;
}

.divInscri {
  padding-bottom: 750px;
}

.main {
  position: absolute;
  width: 50vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .lightBoxStyle();

  .title {
    font-size: 30px;
    color: @primaryColor;
    text-align: center;
    padding: 30px;
    border-bottom: 2px solid @primaryColor;
  }

  .forms {
    display: flex;

    .form {
      .formStyle();
    }
  }

  button {
    .formButtonStyle(50%);
  }
}
</style>

<style scoped>
@media screen and (max-device-width: 1000px) {
  .modal {
    width: 60vw;
  }
}
@media screen and (max-device-width: 600px) {
  .modal {
    width: 80vw;
  }
}
</style>