<!--TODO: NAVBAR Dropdown meme taille que l'element BTN-->

<template>
  <section class="navbar-header">
    <div id ="box" class="box">
      <div @click="trigger_navbar()" style="margin-left: 1rem; cursor: pointer">
        <!-- <font-awesome-icon icon="fa-solid fa-bars" size="xl" />  -->
        click
      </div>
    </div>
    <nav id="nav" class="nav">
      <ul>
        <li class="nav-item">
          <router-link class="nav-link" to="/" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Accueil </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/ListesOffres" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Les Offres </router-link>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropbtn" @click="trigger_navbar()" href=""><font-awesome-icon icon="fa-solid fa-home" />&nbsp; À propos de nous &nbsp;<font-awesome-icon icon="fa-solid fa-caret-down" /></a>
          <div class="dropdown-content">
            <a class="nav-link" href="/PresentationEntreprise"><font-awesome-icon icon="fa-solid fa-home" />&nbsp;L'Entreprise</a>
            <a class="nav-link" href="/PresentationConcept"><font-awesome-icon icon="fa-solid fa-home" />&nbsp;Le Concept</a>
            <a class="nav-link" href="/Contact"><font-awesome-icon icon="fa-solid fa-home" />&nbsp;Nous contacter</a>
          </div>
        </li>

        <li class="nav-item dropdown" v-if="store.userType === 'employer'">
          <a class="nav-link dropbtn" @click="trigger_navbar()" href=""><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Gestion OFFRES &nbsp;<font-awesome-icon icon="fa-solid fa-caret-down" /></a>
          <div class="dropdown-content">
            <router-link class="nav-link" to="/poster-offre" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Poster offre </router-link>
            <router-link class="nav-link" to="/mes-offres" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Mes Offres </router-link>
          </div>
        </li>

        <div class="auth" v-if="store.userName === null">
          <li class="nav-item">
            <router-link class="nav-link" to="/LogInUp" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Se connecter / S'inscrire </router-link>
          </li>
        </div>
        <div class="auth" v-else>
          <li class="nav-item dropdown">
            <a class="nav-link dropbtn" href=""><font-awesome-icon icon="fa-solid fa-house-user" />&nbsp;{{ store.userName }}&nbsp;&nbsp;<font-awesome-icon icon="fa-solid fa-caret-down" /></a>
            <div class="dropdown-content">
              <router-link class="nav-link" to="/Profil" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Mon Compte </router-link>
              <router-link class="nav-link" to="/chat" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Chat </router-link>
              <a class="nav-link" @click="logout" href="#"><font-awesome-icon icon="fa-solid fa-home" />&nbsp; Déconnexion </a>
            </div>
          </li>
        </div>
      </ul>
    </nav>
  </section>
</template>

<script setup>
import { store } from '@/store'
import router from '@/router';

const emit = defineEmits(["triggerNavbar"]);

function logout() {
  localStorage.removeItem("token");
  store.token = null;
  store.userName = null;
  store.userType = null;
  store.userId = null;
  router.push('/LogInUp')
}

function trigger_navbar() {
  emit("triggerNavbar");
  if (document.getElementById("nav").classList.contains("active")) {
    document.getElementById("nav").classList.remove("active");
  } else {
    document.getElementById("nav").classList.add("active");
  }
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.navbar-header {
  padding: 15px 100px 50px;
}

.nav {
  height: fit-content;
  background-color: transparent;
  position: relative;
}
.auth {
  float: right;
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  //overflow: hidden; //DROPDOWN
  text-align: center;
}

li {
  float: left;
  display: block;
}

li a {
  display: flex;
  text-decoration: none;
  text-align: center;
  font-size: 1.25em;
  color: @bgColorDark;
  padding: 14px 16px;
}

li a:hover {
  color: @primaryColorGrey;
}

.router-link-active {
  font-weight: bold;
  color: @primaryColor;
}

.box {
  display: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

</style>

<style scoped>
@media screen and (max-device-width: 500px) {
  nav {
    position: absolute;
    left: -100vw;
    top: 7vh;
  }
  ul {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .auth {
    margin-top: auto;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
  } 
  .box {
    display: flex;
    width: 100vw;
    height: 7vh;
    background-color: #333;
    align-items: center;
    margin: 0;
  }
  .box img {
    width: auto;
    height: 7vh;
  } 
  .luffy {
    position:absolute;
    left:0;
    right:0;
    margin:0 auto;
  }
  nav.active {
    transition: 350ms;
    left: 0;
  }
}
</style>