<template>
  <div class="main">
    <div class="title">
      Mes offres
    </div>
    <div v-if = isLoading>
      <h1>loading...</h1>
    </div>
    <div v-else class="offers">
      <div v-if="offers == null"> Poster une offre ici<router-link to="/poster-offre"> ici</router-link> </div>
      <div v-else class="offer" v-for="(offer, index) in offers" :key="index">
        <div class="name">
          <span class="field"> Mission: </span> {{ offer.name }}
        </div>
        <div class="place">
          <span class="field"> Localisation: </span> {{ offer.place }}
        </div>
        <div class="description">
          <span class="field"> Description: </span> {{ offer.description }}
        </div>
        <div class="automaticAccept">
          <span class="field"> Acceptation automatique: </span> {{ offer.automaticAccept }}
        </div>
        <div class="maxCandidateNumber">
          <span class="field"> Nombre max de candidats: </span> {{ offer.maxCandidateNumber }}
        </div>
        <div class="buttons">
          <div class="change" @click="changeOffer(index)">Changer offre</div>

          <div class="candidacies" @click="router.push('/getCandidats/' + offer.id)">Voir les candidatures</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { store } from "@/store";
import axios from "axios"
import { ref, onMounted } from "vue"
import router from "@/router";

let isLoading = ref(true)
let offers = ref([])
let thisMaxCandidateNumber

onMounted(() => {
  if (store.userType != 'employer') {
    router.push('/')
  } else {
    getOffers()
  }
})

function changeOffer(index) {
  //console.log(offers.value[index].id)
}

async function getOffers() {
  isLoading.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/offers/user/", {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    offers.value = resp.data
    isLoading.value = false
  })
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.main {
  .title {
    .titlePageStyle();
  }

  .offers {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;

    margin: 0 60px;
  }
  .offer {
    display: flex;
    flex-direction: column;
    gap: 15px;

    color: black;
    background-color: rgb(248, 249, 250);
    .lightBoxStyle();
    padding: 22px;
    width: 400px;

    .field {
      font-weight: bold;
    }

    .description {
      height: 50px;
      text-overflow: ellipsis;
      overflow: hidden; 
    }

    .buttons {
      margin-top: 20px;
      display: flex;
    }

    .change, .candidacies {
      .buttonStyle(@primaryColor);
      a { 
        text-decoration: none;
        color: white;
      }
    }
  }
}

</style>
