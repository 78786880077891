<template>

<!--  TODO: Modif JS button -->

  <section class="typePerson" id="typePerson">
    <div class="center-div">
      <div class="container headings text-center">
        <h1 class="Archive text-center font-weight-bold">Je suis à la recherche de ?</h1>
        <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
      </div>
      <br>
      <div class="typePerson-buttons">
        <a @click="showSearch = 1" href="javascript:void(0);" v-bind:class="{ btnCLick: showSearch === 1 }" class="Archive"><font-awesome-icon icon="fa-solid fa-chart-line" /> Développer mon entreprise ?</a>
        <a @click="showSearch = 2" href="javascript:void(0);" class="btnCLick Archive"><font-awesome-icon icon="fa-solid fa-money-bill-trend-up" /> Generer de l'argent ?</a>
      </div>

      <div v-if="showSearch === 1" class="imagetexte">
        <div class="backgroundimage"></div>
        <div class="padding">
          <div id="container" class="blabla">
            <br>
            <h1 class="Archive">Développer mon entreprise !</h1>

            <div class="bloc-lineIconTexte">
              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-circle"></i>
                </div>
                <div class="texte">
                  <h2>Tous types de status social !</h2>
                  <p>(TPE, PME, Micro-entreprise, Societé, Groupe, ect...).</p>
                </div>
              </div>

              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-desktop"></i>
                </div>
                <div class="texte">
                  <h2>Simplifier les contraintes temporaires</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
              </div>

              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-desktop"></i>
                </div>
                <div class="texte">
                  <h2>Benificier de suivi et de professionnalisme</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
              </div>

            </div>

            <div class="result-bloc">

              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-forward"></i>
                </div>
                <div class="texte">
                  <h2>Vous recherchez du personnel</h2>
                  <p class="textTache">Pour effectuer des tâches simples ?</p>
                </div>
                <div class="icon2">
                  <i class="fa-3x fa fa-backward"></i>
                </div>
              </div>

            </div>

            <div class="typePerson-buttons">
              <a  class="Archive"><font-awesome-icon icon="fa-solid fa-plus" /> Proposer une OFFRE</a>
            </div>

            <br>

          </div>
        </div>
      </div>

      <div v-if="showSearch === 2" class="imagetexte">
        <div class="backgroundimage"></div>
        <div class="padding">
          <div id="container" class="blabla">
            <br>
            <h1 class="Archive">Generer de l'argent !</h1>

            <div class="bloc-lineIconTexte">
              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-circle"></i>
                </div>
                <div class="texte">
                  <h2>Quand je veux ?</h2>
                  <p>(TPE, PME, Micro-entreprise, Societé, Groupe, ect...).</p>
                </div>
              </div>

              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-desktop"></i>
                </div>
                <div class="texte">
                  <h2>Tout en continuant mes activités ?</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
              </div>

            </div>

            <div class="result-bloc">

              <div class="lineIconTexte">
                <div class="icon">
                  <i class="fa-3x fa fa-forward"></i>
                </div>
                <div class="texte">
                  <h2>Vous recherchez emploie rapide</h2>
                  <p class="textTache">En effectuant des tâches simples ?</p>
                </div>
                <div class="icon2">
                  <i class="fa-3x fa fa-backward"></i>
                </div>
              </div>

            </div>

            <div class="typePerson-buttons">
              <a  class="Archive"><font-awesome-icon icon="fa-solid fa-plus" /> Postuler à une OFFRE</a>
            </div>

            <br>

          </div>
        </div>
      </div>

    </div>
  </section>

  <section v-if="showSearch === 3" class="secDoubleCol">

    <div class="doubleCol">
      <div class="column left">
        <h1 class="Archive">Développer mon entreprise !</h1>
        <div class="bloc-lineIconTexte">
          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-circle"></i>
            </div>
            <div class="texte">
              <h2>Tous types de status social !</h2>
              <p>(TPE, PME, Micro-entreprise, Societé, Groupe, ect...).</p>
            </div>
          </div>

          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-desktop"></i>
            </div>
            <div class="texte">
              <h2>Simplifier les contraintes temporaires</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>

          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-desktop"></i>
            </div>
            <div class="texte">
              <h2>Benificier de suivi et de professionnalisme</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>

        </div>

        <div class="result-bloc">

          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-forward"></i>
            </div>
            <div class="texte">
              <h2>Vous recherchez du personnel</h2>
              <p class="textTache">Pour effectuer des tâches simples ?</p>
            </div>
            <div class="icon2">
              <i class="fa-3x fa fa-backward"></i>
            </div>
          </div>

        </div>

        <div class="typePerson-buttons">
          <a  class="Archive"><font-awesome-icon icon="fa-solid fa-plus" /> Proposer une OFFRE</a>
        </div>

      </div>
      <div class="column right">
        <h1 class="Archive">Generer de l'argent !</h1>

        <div class="bloc-lineIconTexte">
          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-circle"></i>
            </div>
            <div class="texte">
              <h2>Quand je veux ?</h2>
              <p>(TPE, PME, Micro-entreprise, Societé, Groupe, ect...).</p>
            </div>
          </div>

          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-desktop"></i>
            </div>
            <div class="texte">
              <h2>Tout en continuant mes activités ?</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>

        </div>

        <div class="result-bloc">

          <div class="lineIconTexte">
            <div class="icon">
              <i class="fa-3x fa fa-forward"></i>
            </div>
            <div class="texte">
              <h2>Vous recherchez emploie rapide</h2>
              <p class="textTache">En effectuant des tâches simples ?</p>
            </div>
            <div class="icon2">
              <i class="fa-3x fa fa-backward"></i>
            </div>
          </div>

        </div>

        <div class="typePerson-buttons">
          <a  class="Archive"><font-awesome-icon icon="fa-solid fa-plus" /> Postuler à une OFFRE</a>
        </div>
      </div>
    </div>

  </section>



</template>

<script>


export default {
  data() {
    return {
      showSearch: 3
    }
  }
}
</script>

<style scoped lang="less">

@import "@/shared/theme.less";

.secDoubleCol {
  padding-bottom: 50px;
}

.doubleCol {
  display: flex;
  justify-content: space-between;
  min-height: 500px;
  padding-right: 150px;
  padding-left: 150px;
}
.column {
  width: 48%;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  position: relative;
}

.column:hover{
  box-shadow: -10px 0px 20px 0 rgba(0,0,0,0.3);
  transform: translateY(-20px);
}

.column::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
  border-radius: 10px;
  opacity: 0.1;
}

.bloc-lineIconTexte {
  padding-top: 40px;
  padding-bottom: 40px;
}

.result-bloc {
  padding-bottom: 40px;
}

.lineIconTexte {
  display:flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.lineIconTexte .icon {
  float:left;
  padding-right: 20px;
}

.lineIconTexte .icon2 {
  float:right;
  padding-left: 20px;
}

.lineIconTexte .texte {
  overflow:auto;
}

.textTache {
  font-size: 22px;
  text-decoration: underline;
}

.typePerson{
  background-color: #f7f7f9;
  padding: 50px 0;
  margin-bottom: 50px;
}

.center-div {
  text-align: center;
}

.typePerson-buttons a {
  border: 1px solid @primaryColorGrey;
  text-decoration: none;
  border-radius: 100px;
  margin: 0 5px;
  padding: 12px 55px;
  outline: none;
  color: #FFFFFF;
  background-color: @primaryColor;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}
.typePerson-buttons a:hover{
  color: @primaryColor;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}

.btnCLick {
  text-decoration: underline;
}


.imagetexte{
  padding-top: 5em;
  display: flex;
  width:100%;
  margin-left:10%;
  min-height:450px;
}

.padding{
  padding-top:40px;
  padding-bottom:40px;
  padding-left: 50px;
}
.backgroundimage{
  background:url("https://www.bpifrance.fr/sites/default/files/inline-images/630-bonheur-en-entreprise.png");
  background-size: cover;
  position:relative;
  width:70%;
  max-height: 770px;
  max-width: 520px;
}

.blabla{
  position:relative;
  word-wrap:break-word;
  height:100%;
  min-height: 650px;
  min-width: 820px;
  width:75%;
  background:white;
  margin-left:-10%;
  box-shadow: 0px 0px 15px grey;

}

.blabla > p{
  padding:5%;
  padding-right:10%;
  padding-left:10%;
  font-size:40px;
  word-wrap:break-word;
}


</style>