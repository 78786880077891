import { createRouter, createWebHistory } from "vue-router"
import Home from "@/views/Home/Home.vue"
import Offers from '@/views/Offers.vue'
import NotFound from '@/views/NotFound.vue'
import Signup from '@/views/Signup.vue'
import Signin from "@/views/Signin.vue"
import PresentationEntreprise from "@/views/PresentationEntreprise/PresentationEntreprise.vue";
import Account from "@/views/Account.vue"
import PostOffer from "@/views/PostOffer.vue"
import MyOffers from "@/views/MyOffers.vue"
import Candidacy from "@/views/Candidacy.vue"
import Offer from "@/components/Offer.vue"
import Candidacies from "@/components/Candidacies.vue"
import Chat from "@/views/Profil/Sections/Chat.vue"
import PresentationConcept from "@/views/PresentationConcept/PresentationConcept.vue";
import Contact from "@/views/Contact/Contact.vue"
import LogInUp from "@/views/LogInUp/LogView.vue"
import Welcome from "@/views/Welcome/WelcomeView.vue";
import ListeOffres from "@/views/Offres/OffresView.vue";
import Test from "@/views/Test/Test.vue";
import getOffre from "@/views/Offres/Sections/getOffre.vue";
import getCandidats from "@/views/Offres/Sections/getCandidat.vue";
import Profil from "@/views/Profil/ProfilView.vue";

const routes = [
    {
      name: Home,
      path: "/",
      component: Home,
      meta: {
          title: "Gap extra"
      }
    },
    {
        name: Profil,
        path: "/Profil",
        component: Profil,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: Test,
        path: "/Test",
        component: Test,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: ListeOffres,
        path: "/ListesOffres",
        component: ListeOffres,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: Welcome,
        path: "/Welcome",
        component: Welcome,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: PresentationEntreprise,
        path: "/PresentationEntreprise",
        component: PresentationEntreprise,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: PresentationConcept,
        path: "/PresentationConcept",
        component: PresentationConcept,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: Contact,
        path: "/Contact",
        component: Contact,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: LogInUp,
        path: "/LogInUp",
        component: LogInUp,
        meta: {
            title: "Gap extra"
        }
    },
    {
        name: getOffre,
        path: "/Offre/:id",
        component: getOffre,
        meta: {
            title: "getOffre"
        }
    },
    {
      name: Offers,
      path: "/offres",
      component: Offers,
      children: [
        {
          path: 'offre/:id',
          component: Offer,
        },
      ],
      meta: {
          title: "Offres"
      }
    },
    {
        name: getCandidats,
        path: "/getCandidats/:offerId",
        component: getCandidats,
        props: true,
        meta: {
            title: "Mes offres"
        }
    },
    {
      name: MyOffers,
      path: "/mes-offres",
      component: MyOffers,
      children: [
        {
          path: 'candidacies/:offerId',
          component: Candidacies,
          props: true
        },
      ],
      meta: {
        title: "Mes offres"
      }
    },
    {
      name: Candidacies,
      path: "/candidacies/:offerId",
      component: Candidacies,
      meta: {
        title: "Candidatures"
      }
    },
    {
      name: PostOffer,
      path: "/poster-offre",
      component: PostOffer,
      meta: {
          title: "Poster offre"
      }
    },
    {
      name: Candidacy,
      path: "/candidacy/:id",
      component: Candidacy,
      meta: {
          title: "Candidature"
      }
    },
    // {
    //   name: Test,
    //   path: "/test",
    //   component: Test,
    //   meta: {
    //       title: "Test"
    //   }
    // },
    {
      name: Chat,
      path: "/chat",
      component: Chat,
      meta: {
          title: "Chat"
      }
    },
    {
      name: Account,
      path: "/compte",
      component: Account,
      meta: {
          title: "Compte"
      }
    },
    {
      name: Signup,
      path: "/inscription",
      component: Signup,
      meta: {
          title: "Inscription"
      }
    },
    {
      name: Signin,
      path: "/connexion",
      component: Signin,
      meta: {
          title: "Connexion"
      }
    },
    {
      name: "NotFound",
      path: "/:pathMatch(.*)",
      component: NotFound,
      meta: {
          title: "404"
      }
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
    document.title = to.meta.title;
})

export default router;