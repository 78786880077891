<template>
  <section id="team" class="team-area">
    <div class="align-element">
      <div class="row team-items">
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <img class="img-fluid" src="https://i.ibb.co/JC4skS0/team-animate.jpg" alt="Thumb">
              <div class="overlay">
                <h4>Lorem Ipsum</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="social">
                  <ul>
                    <li class="twitter">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/twitter_50px.png"></a>
                    </li>
                    <li class="pinterest">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/Pinterest_50px.png"></a>
                    </li>
                    <li class="instagram">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/Instagram_50px.png"></a>
                    </li>
                    <li class="vimeo">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/vimeo_50px.png"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="info">
              <span class="message">
                <a href="#"><font-awesome-icon icon="fa-solid fa-envelope-open" /></a>
              </span>
              <h4>Lorem Ipsum</h4>
              <span class="Archive">Lorem Ipsum</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <img class="img-fluid" src="https://i.ibb.co/JC4skS0/team-animate.jpg" alt="Thumb">
              <div class="overlay">
                <h4>Lorem Ipsum</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="social">
                  <ul>
                    <li class="twitter">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/twitter_50px.png"></a>
                    </li>
                    <li class="instagram">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/Instagram_50px.png"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="info">
              <span class="message">
                <a href="#"><font-awesome-icon icon="fa-solid fa-envelope-open" /></a>
              </span>
              <h4>Lorem Ipsum</h4>
              <span class="Archive">Lorem Ipsum</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <img class="img-fluid" src="https://i.ibb.co/JC4skS0/team-animate.jpg" alt="Thumb">
              <div class="overlay">
                <h4>Lorem Ipsum</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="social">
                  <ul>
                    <li class="twitter">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/twitter_50px.png"></a>
                    </li>
                    <li class="pinterest">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/Pinterest_50px.png"></a>
                    </li>
                    <li class="instagram">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/Instagram_50px.png"></a>
                    </li>
                    <li class="vimeo">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/vimeo_50px.png"></a>
                    </li>
                    <li class="youtube">
                      <a href="#"><img class="icon-social" src="../../../assets/icons/youtube_logo_50px.png"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="info">
              <span class="message">
                <a href="#"><font-awesome-icon icon="fa-solid fa-envelope-open" /></a>
              </span>
              <h4>Lorem Ipsum</h4>
              <span class="Archive">Lorem Ipsum</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

</script>

<style scoped lang="less">

@import "@/shared/theme.less";

.icon-social {
  width: 100%;
}

.img-fluid {
  width : 100%; height : 100%;
  border: none;
}

.align-element {
  text-align: center;
  display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
}
section {
  padding: 60px 0;
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.site-heading h2 span {
  color: @primaryColor;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.site-heading h4::before {
  background: @primaryColor none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading h2 span {
  color: @primaryColor;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.team-area .single-item {
  margin-bottom: 30px;
}

.single-item {
  float: left;
  margin: 10px;
  background-color: #ccc;
}

.thumb {
  width: 350px;
  height: 400px;
}

.team-area .item .thumb {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.team-area .item .thumb::after {
  background: #232323 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
}

.team-area .team-items .item:hover .thumb::after {
  overflow: auto;
  opacity: 0.7;
}

.team-area .item .thumb .overlay {
  top: -100%;
  left: 0;
  padding: 20px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: 1;
}

.team-area .item:hover .thumb .overlay {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.team-area .item .thumb .overlay p {
  color: #ffffff;
}

.team-area .item .thumb .overlay h4 {
  color: #ffffff;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.team-area .item .thumb img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.team-area .item:hover .thumb img {
  opacity: .6;
}

.social {
  padding-top: 35px;
}

.team-area .item .thumb .social li {
  display: inline-block;
}

.team-area .item .thumb .social li a {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 0 2px;
  text-align: center;
  width: 40px;
}

.team-area .info {
  background: #ffffff none repeat scroll 0 0;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  padding: 40px 20px 20px;
  position: relative;
  text-align: center;
  z-index: 9;
}

.team-area .info .message {
  height: 50px;
  line-height: 40px;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}

.team-area .info .message a {
  background: #fff none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  box-sizing: border-box;
  color: @primaryColor;
  display: inline-block;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  width: 50px;
}

.team-area .info .message a i {
  font-weight: 500;
}

.team-area .info h4 {
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.team-area .info span {
  color: @primaryColor;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

</style>