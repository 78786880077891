<template>
  <!-- ********************* project (completed) starts ****************************** -->

  <section class="project-work">
    <div class="center-div">
      <div class="container headings text-center">
        <h1 class="Archive text-center font-weight-bold">Quelques chiffres ?</h1>
        <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
      </div>
    </div>
    <br>
    <div class="container d-flex justify-content-around align-items-center text-center">
      <div>
        <h1 class="count">1500</h1>
        <p>Lorem Ipsum</p>
      </div>

      <div>
        <h1 class="count">500</h1>
        <p>Lorem Ipsum</p>
      </div>

      <div>
        <h1 class="count">2500</h1>
        <p>Lorem Ipsum</p>
      </div>

      <div>
        <h1 class="count">700</h1>
        <p>Lorem Ipsum</p>
      </div>

      <div>
        <h1 class="count">500</h1>
        <p>Lorem Ipsum</p>
      </div>

      <div>
        <h1 class="count">500</h1>
        <p>Lorem Ipsum</p>
      </div>

      <div>
        <h1 class="count">500</h1>
        <p>Lorem Ipsum</p>
      </div>
    </div>
  </section>
  <!-- ********************* project (completed) ends ****************************** -->
</template>

<script>
export default {
  name: "ProjectCompleted"
}
</script>

<style scoped lang="less">

@import "@/shared/theme.less";

/* ********************* project (completed) starts ****************************** */

.project-work{
  margin: 100px 0;
}
.project-work h1{
  font-size: 2rem;
  text-align: center;
}
/* ********************* project (completed) ends ****************************** */
</style>