<template>
  <div class="main">
    <div class="title">
      Toutes les offres
    </div>
    <div v-if = isLoading>
      <h1>loading...</h1>
    </div>
    <div v-else class="offers">
      <div class="offer" v-for="(offer, index) in offers" :key="index">
        <div class="name">
          <span class="field"> Mission: </span> {{ offer.name }}
        </div>
        <div class="place">
          <span class="field"> Localisation: </span> {{ offer.place }}
        </div>
        <div class="description">
          <span class="field"> Description: </span> {{ offer.description }}
        </div>
        <div class="details" @click="openOffer(offer.id)">Fiche complète</div>
        <div v-if="store.userType=='candidate'" @click="openApply(offer.id)" class="apply">
          Candidater
        </div>
      </div>
    </div>
    <Apply @modal-boolean="closeApply()" @no="closeApply()" :isModalOpen="isApplyOpen" :offerId="thisOfferId"/>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { store } from "@/store";
import axios from "axios"
import { ref, onMounted } from "vue"
import Apply from "@/components/Apply.vue";
import router from "@/router";

let isLoading = ref(true)
let isApplyOpen = ref(false)
let thisOfferId = ""
let offers = ref([])

onMounted(() => {
  getOffers()
})

function openOffer(offerId) {
  router.push('/offres/offre/' + offerId)
}

function openApply(offerId) {
  isApplyOpen.value = true;
  thisOfferId = offerId
}

function closeApply() {
  isApplyOpen.value = false;
}

async function getOffers() {
  isLoading.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/offers")
  .then(resp => {
    offers.value = resp.data
    isLoading.value = false
  })
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.main {
  .title {
    .titlePageStyle();
  }

  .offers {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;

    margin: 0 60px;
  }
  .offer {
    display: flex;
    flex-direction: column;
    gap: 15px;

    color: black;
    background-color: @bgColorLight;
    .lightBoxStyle();
    padding: 22px;
    width: 400px;
    height: 200px;

    .field {
      font-weight: bold;
    }

    .description {
      height: 50px;
      text-overflow: ellipsis;
      overflow: hidden; 
    }

    .details, .apply {
      margin-top: 20px;
      .buttonStyle(@primaryColor);
    }
  }
}

</style>
