<template>

<!--  TODO: BUG footer icon MINI-->

  <div class="header" id="topheader">
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
      <div class="container text-uppercase p-2">
        <router-link class="navbar-brand font-weight-bold text-white" to="/" @click="trigger_navbar()"><font-awesome-icon icon="fa-solid fa-house" />  GAP Extra </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">Accueil <span class="sr-only">(current)</span></a>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/ListesOffres" @click="trigger_navbar()"> Les Offres </router-link>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropbtn" href="">À propos de nous <i class="fa fa-caret-down"></i></a>
              <div class="dropdown-content">
                <a class="nav-link" href="/PresentationEntreprise"><font-awesome-icon icon="fa-solid fa-home" />&nbsp;L'Entreprise</a>
                <a class="nav-link" href="/PresentationConcept"><font-awesome-icon icon="fa-solid fa-home" />&nbsp;Le Concept</a>
                <a class="nav-link" href="/Contact"><font-awesome-icon icon="fa-solid fa-home" />&nbsp;Nous contacter</a>
              </div>
            </li>

<!--            <li class="nav-item dropdown">-->
<!--              <a class="nav-link dropbtn" href=""><i class="fa fa-caret-down"></i> Dropdown</a>-->
<!--              <div class="dropdown-content">-->
<!--                <a class="nav-link" href="#">Link 1</a>-->
<!--                <a class="nav-link" href="#">Link 2</a>-->
<!--                <a class="nav-link" href="#">Link 3</a>-->
<!--              </div>-->
<!--            </li>-->

            <li class="nav-item dropdown" v-if="store.userType === 'employer'">
              <a class="nav-link dropbtn" href="">Gestion OFFRES <i class="fa fa-caret-down"></i></a>
              <div class="dropdown-content">
                <router-link class="nav-link" to="/poster-offre" @click="trigger_navbar()"> Poster offre </router-link>
                <router-link class="nav-link" to="/mes-offres" @click="trigger_navbar()"> Mes Offres </router-link>
              </div>
            </li>

            <div class="auth" v-if="store.userName === null">
              <li class="nav-item">
                <router-link class="nav-link" to="/LogInUp" @click="trigger_navbar()"> Se connecter / S'inscrire </router-link>
              </li>
            </div>
            <div class="auth" v-else>
              <li class="nav-item dropdown">
                <a class="nav-link dropbtn" href="">{{ store.userName }} <i class="fa fa-caret-down"></i></a>
                <div class="dropdown-content">
                  <router-link class="nav-link" to="/Profil" @click="trigger_navbar()"> Mon Compte </router-link>
                  <router-link class="nav-link" to="/chat" @click="trigger_navbar()"> Chat </router-link>
                  <a class="nav-link" @click="logout()"> Déconnexion </a>
                </div>
              </li>
            </div>

          </ul>
        </div>
      </div>
    </nav>
    <section class="header-section">
      <div class="center-div">
        <h1 class="font-weight-bold">Bienvenue dans une nouvelle ére</h1>
        <p><b>GAP Extra</b>  est un portail vous permettant de découvrir et de profiter d'une révolution du monde du travail.</p>
        <div class="header-buttons">
          <a  class="Archive" href="#typePerson"><font-awesome-icon icon="fa-solid fa-right-long" /> Voir la suite !</a>
          <a  class="Archive" href="">A propos de nous ?</a>
          <router-link class="Archive" to="/offres" @click="trigger_navbar()"> Voir les offres </router-link>
        </div>
      </div>
    </section>
  </div>

  <!-- ***************** header part end ************************** -->

  <!-- ********************* three extra header div starts ******************** -->

  <section class="header-extradiv">
    <div class="container">
      <div class="row">
        <div class="extradiv col-lg-4 col-md-4 col-12">
          <a class="iconExtra" href="#"><i class="fa-3x fa fa-desktop"></i></a>
          <h2 class="Archive">Lorem ipsum</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
        </div>
        <div class="extradiv col-lg-4 col-md-4 col-12">
          <a class="iconExtra" href="#"><i class="fa-3x fa fa-trophy"></i></a>
          <h2 class="Archive">Lorem ipsum</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
        </div>
        <div class="extradiv col-lg-4 col-md-4 col-12">
          <a class="iconExtra" href="#"><i class="fa-3x fa fa-magic"></i></a>
          <h2 class="Archive">Lorem ipsum</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
        </div>
      </div>
    </div>
  </section>

  <!-- ********************* three extra header div starts ******************** -->
</template>

<script setup>
import { store } from '@/store'
import router from '@/router';

// eslint-disable-next-line no-undef
const emit = defineEmits(["triggerNavbar"]);

function logout() {
  localStorage.removeItem("token");
  store.token = null;
  store.userName = null;
  store.userType = null;
  store.userId = null;
  router.push('/LogInUp')
}

function trigger_navbar() {
  emit("triggerNavbar");
  if (document.getElementById("nav").classList.contains("active")) {
    document.getElementById("nav").classList.remove("active");
  } else {
    document.getElementById("nav").classList.add("active");
  }
}
</script>

<style scoped lang="less">

@import "@/shared/theme.less";


/* XXXXXXXXXXXXXXXXXXXXX common style ends here XXXXXXXXXXXXXXXXXXX */
.header{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(@primaryColorTransparent, @primaryColorLighterTransparent), url("../../../assets/img/screen-home-night.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(100% 0, 100% 77%, 50% 100%, 0 75%, 0 0);
  position: relative;
}
.header::before{
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  z-index: -1;
  background: linear-gradient(to right,  @primaryColor 0%, @primaryColorLighter 0%, @primaryColor 100%);
}
.header-section{
  width: 100%;
  height: inherit;
  color: #fff;
  text-align: center;
  position: relative;
}
.center-div{
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.header-buttons a {
  border: 1px solid #fff;
  border-radius: 100px;
  margin: 0 5px;
  padding: 12px 35px;
  outline: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}
.header-buttons a:hover{
  text-decoration: none;
  color: @primaryColor;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.center-div p{
  font-size: 1.3rem;
  padding: 10px 0 20px 0;
  color: #fff;
}
/* ********************* three extra header div starts ******************** */

.navbar::before{
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  z-index: -1;
  background: linear-gradient(to right,  @primaryColor 0%, @primaryColorLighter 0%, @primaryColor 100%);
}
.nav-item a{
  color: #fff!important;
  font-weight: bold;
}

.nav-item:hover a:hover{
  color: @primaryColorLighter!important;
  font-weight: bold;
}

.dropdown {
  float: left;
  //overflow: hidden;
}

.dropdown .dropbtn {
  color: #fff!important;
  font-weight: bold;
}


.dropdown-content {
  display: none;
  position: absolute;
  //background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content  {
  //background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
  display: block;
}

.header-extradiv{
  width: 100%;
  height: auto;
  margin: 100px 0;
  text-align: center;
}
.extradiv p{text-align: justify;}
.extradiv{
  background-color: #fff;
  border: none;
  padding: 30px!important;
  border-radius: 3px;
  transition: 0.3s;
}
.extradiv:hover{
  box-shadow: -10px 0px 20px 0 rgba(0,0,0,0.3);
  transform: translateY(-20px);
}

.iconExtra {
  color: @primaryColor;
}


/* ********************* three extra header div ends ******************** */

</style>