<template>
  <section class="secLogin">
  <div class="divLogin">
    <div class="main">
      <div class="title">
        Connexion
      </div>
      <div class="form">
        <div class="txt_field">
          <input @keyup.enter="submit" v-model="email" type="text" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label v-if="!badAuth">Email</label>
          <label v-if="badAuth" style="color: orange;">Mauvais email</label>
        </div>
        <div class="txt_field">
          <input @keyup.enter="submit" v-model="password" type="password" required>
          <span :style="{'--lineColor': lineColor}"></span>
          <label v-if="!badAuth">Mot de Passe</label>
          <label v-if="badAuth" style="color: orange;">ou mauvais mot de passe</label>
        </div>
      </div>
      <button @click="submit" :class="{ connected: connected }">
          <div v-if="!connected && !inConnection">Connexion</div>
          <div v-if="inConnection"><Spinner color="white"/></div>
          <div v-if="connected" style="color: white;">Connecté :)</div>
        </button>
    </div>
  </div>
  </section>
</template>

<script setup>
import { ref } from "vue"
import axios from "axios";
import Spinner from "../components/Spinner.vue"
import { store } from "@/store"; 
import router from "@/router";
import parseJWT from '@/utils/parseJWT'

const emit = defineEmits(["signinWorked"]);

const lineColor = ref("rgb(37, 212, 108)");

let connected = ref(false);
let inConnection = ref(false);
let badAuth = ref(false);

let email = ref("");
let password = ref("");

async function submit() {
  inConnection.value = true;
  const obj = {
    "email": email.value,
    "password": password.value
  };
  await axios.post(process.env.VUE_APP_API_URL + "/signin", JSON.stringify(obj))
  .then(resp => {
    if (resp.status == 200) {
      inConnection.value = false;
      connected.value = true;

      const parsedJWT = parseJWT(resp.data);
      localStorage.setItem("token", resp.data);
      store.token = resp.data
      store.userName = parsedJWT.lastName + " " + parsedJWT.firstName
      store.userId = parsedJWT.userId
      store.userType = parsedJWT.userType

      connected.value = true;
      password.value = "";

      setTimeout(() => {
        connected.value = false;
        router.push('/')
      }, 500)
    }
  })
  .catch(() => {
    inConnection.value = false;
    connected.value = false;
    badAuth.value = true;
    lineColor.value = "orange";
      setTimeout(() => {
        badAuth.value = false;
      }, 3000)
  })
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";


.secLogin {
  position:relative;
}

.divLogin {
  padding-bottom: 650px;
}

.main {
  position: absolute;
  width: 35vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .lightBoxStyle();

  .title {
    font-size: 30px;
    color: @primaryColor;
    text-align: center;
    padding: 30px;
    border-bottom: 2px solid @primaryColor;
  }

  .form {
    .formStyle();
  }

  button {
    .formButtonStyle(80%);
  }
}
</style>