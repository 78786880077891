<template>

  <!-- ********************* our happy clients starts ****************************** -->

  <section class="happyclients">
    <div class="container headings text-center">
      <h1 class="text-center font-weight-bold">AVIS DE NOS CLIENTS</h1>
      <p class="text-center text-captalize pt-1">Salut ca va oui bonjour</p>
    </div>
    <div id="demo" class="carousel slide" data-ride="carousel">

      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li @click="activeIndex = 0" v-bind:class="{ active: activeIndex === 0 }"></li>
        <li @click="activeIndex = 1" v-bind:class="{ active: activeIndex === 1 }"></li>
        <li @click="activeIndex = 2" v-bind:class="{ active: activeIndex === 2 }"></li>
      </ul>
      <br>

      <div class="carousel-inner container">
        <div class="carousel-item active">
          <div v-for="(item, index) in listAvis" :key="index" v-show="index === activeIndex">
           <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="box">
                  <a href="#"><img :src="item.Avis1[0].img" class="img-fluid img-thumbnail"></a>
                  <p class="m-4">{{ item.Avis1[0].text }}</p>
                  <h1>{{ item.Avis1[0].title }}</h1>
                  <h2>{{ item.Avis1[0].type }}</h2>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                <div class="box">
                  <a href="#"><img :src="item.Avis2[0].img" class="img-fluid img-thumbnail"></a>
                  <p class="m-4">{{ item.Avis2[0].text }}</p>
                  <h1>{{ item.Avis2[0].title }}</h1>
                  <h2>{{ item.Avis2[0].type }}</h2>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                <div class="box">
                  <a href="#"><img :src="item.Avis3[0].img" class="img-fluid img-thumbnail"></a>
                  <p class="m-4">{{ item.Avis3[0].text }}</p>
                  <h1>{{ item.Avis3[0].title }}</h1>
                  <h2>{{ item.Avis3[0].type }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Left and right controls -->
      <a class="carouselControl carousel-control-prev" @click="previous">
        <font-awesome-icon icon="fa-solid fa-left-long" />
      </a>
      <a class="carouselControl carousel-control-next" @click="next">
        <font-awesome-icon icon="fa-solid fa-right-long"/>
      </a>

    </div>
  </section>


  <!-- ********************* our happy clients end ****************************** -->
</template>

<script>
export default {
  data() {
    return {
      listAvis: [
        {
          Avis1: [
            {
              title: 'Titre 1 Avis1.1',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ],
          Avis2: [
            {
              title: 'Titre 1 Avis2.1',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ],
          Avis3: [
            {
              title: 'Titre 1 Avis3.1',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ]
        },
        {
          Avis1: [
            {
              title: 'Titre 1 Avis1.2',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ],
          Avis2: [
            {
              title: 'Titre 1 Avis2.2',
              img: 'https://e7.pngegg.com/pngimages/670/509/png-clipart-avatar-female-girls-avatar-purple-face-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ],
          Avis3: [
            {
              title: 'Titre 1 Avis3.2',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ]
        },
        {
          Avis1: [
            {
              title: 'Titre 1 Avis1.3',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ],
          Avis2: [
            {
              title: 'Titre 1 Avis2.3',
              img: 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ],
          Avis3: [
            {
              title: 'Titre 1 Avis3.3',
              img: 'https://e7.pngegg.com/pngimages/551/804/png-clipart-discord-logo-avatar-avatar-heroes-logo-thumbnail.png',
              text: 'Texte 1',
              type: 'Candidat',
            }
          ]
        }
      ],
      activeIndex: 0
    }
  },
  methods: {
    next() {
      this.activeIndex = (this.activeIndex + 1) % this.listAvis.length
    },
    previous() {
      this.activeIndex = (this.activeIndex + this.listAvis.length - 1) % this.listAvis.length
    }
  }
}

</script>

<style scoped lang="less">

@import "@/shared/theme.less";

/* ********************* our happy clients starts ******************************  */

.carouselControl {
  color: @primaryColor;
  font-size: 80px;
}

.happyclients{
  width: 100%;
  height: 100vh;
  padding: 80px 0;
}
.box{
  text-align: center;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  border-radius: 2px;
  transition: 0.3s ease;
  min-height: 350px;
  min-width: 350px;
}
.box:hover{
  background: @primaryColor;
}
.box:hover p{
  color: #fff;
}
.carousel-indicators{
  position: absolute;
  right: 0;
  bottom: -60px!important;
}
.carousel-indicators li{
  background-color: @primaryColor!important;
}
.box a{
  position: relative;
}
.box a img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 20px;
}
.box a::after{
  content: "\f10d";
  font-family: FontAwesome;
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, @primaryColor 0%, @primaryColorLighter 0%, @primaryColor 100%);
  color: #fff;
  position: absolute;
  top: 120%;
  left: 70%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-listAvis: center;
}
.box h1{
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}
.box h2{
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 20px;
}
/* ********************* our happy clients ends ******************************  */
</style>