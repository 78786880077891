<template>
  <div class="modal-bg">
    <div class="modal" ref="modal">
      <div v-if = isLoadingOffer>
        <h1>loading...</h1>
      </div>
      <div v-else class="main">
        <div class="title">
          {{ offer.name }}
        </div>
        <div class="offer">
          <div class="name">
            <span class="field"> Mission: </span> {{ offer.name }}
          </div>
          <div class="place">
            <span class="field"> Localisation: </span> {{ offer.place }}
          </div>
          <div class="description">
            <span class="field"> Description: </span> {{ offer.description }}
          </div>
          <div class="price">
            <span class="field"> Prix: </span> {{ offer.price }} €
          </div>
          <div class="dateCreation">
            <span class="field"> Date de création: </span> {{ offer.dateCreation }}
          </div>

          <a v-if="store.userType==='candidate'" @click="isApplyOpen = !isApplyOpen">Candidater</a>

          <div v-if="isApplyOpen">
            <div class="title">
              SALUT
            </div>
            <div v-if="alreadyCandidate" class="title" style="color: red;">
              VOUS AVEZ DEJA CANDIDATER
            </div>
            <div class="modal-bg" v-if="isApplyOpen">
              <div class="main" ref="modal">
                <div class="title">Voulez vous candidater à cette offre ?</div>
                <textarea v-model="application" placeholder="Votre candidature"></textarea>
                <div class="buttons">
                  <button @click="applyToOffer()" class="yes">
                    Candidater
                  </button>
                  <button @click="no()" class="no"> Annuler </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {

  data() {
    return {
      isApplyOpen: false,
    }
  }
}

</script>

<script setup>
import { store } from "@/store";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue"
import { onClickOutside } from "@vueuse/core";
import axios from "axios";
import router from "@/router";

const route = useRoute()

let isLoadingOffer = ref(true)
let offer = ref(null)

const props = defineProps({
  isModalOpen: Boolean,
  offerId: String
})

const emit = defineEmits(["modalBoolean", "no"]);
const modal = ref(null);

let alreadyCandidate = false;
let application = ref("")

onClickOutside(modal, () => {
  emit("modalBoolean");
});

function no() {
  emit("no")
}

async function applyToOffer() {
  const obj = {
    'content': application.value,
    'offerId': route.params.id
  }

  await axios.post(process.env.VUE_APP_API_URL + "/candidacies", JSON.stringify(obj), {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
      .then(resp => {
        console.log(resp.status)
        emit("no")
        application.value = ""
      })
      .catch(err => {
        console.log(err.response.status)
        alreadyCandidate = true
      })
}

onMounted(() => {
  getOffer()
})

onClickOutside(modal, () => {
  router.go(-1)
});

async function getOffer() {
  isLoadingOffer.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/offers/" + route.params.id)
  .then(resp => {
      offer.value = resp.data
      isLoadingOffer.value = false
  })
}

</script>

<style scoped lang="less">
//.modal-bg {
//  position: fixed;
//  top: 0;
//  left: 0;
//  width: 100vw;
//  height: 100vh;
//
//  background: rgba(15, 19, 21, 0.8);
//  backdrop-filter: blur(1rem);
//
//  .modal {
//    position: absolute;
//    width: 600px;
//    height: 500px;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//
//    color: black;
//    background-color: rgb(248, 249, 250);
//    border-radius: 14px;
//  }
//}

.main {
  margin-top: 60px;

  .title {
    font-size: 27px;
    margin-bottom: 20px;
    color: black;
    font-weight: 600;
    text-align: center;
  }

  .offer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;

    padding: 22px;
    width: 400px;

    font-size: 20px;

    .field {
      font-weight: bold;
    }
  }
}

</style>

<style scoped>
@media screen and (max-device-width: 1000px) {
  .modal {
    width: 60vw;
  }
}
@media screen and (max-device-width: 600px) {
  .modal {
    width: 80vw;
  }
}
</style>