<template>
  <div class="main">
    <div v-if = isLoading>
      <h1>loading...</h1>
    </div>
    <div v-else>
      <div class="title">Candidatures {{ acceptedNumber }} / {{ maxCandidateNumber }}</div>
      <div class="candidacies">
        <div class="candidacy" v-for="(candidacy, index) in candidacies" :key="index">
          <div class="user">Utilisateur: {{ candidacy.userInfos.lastName }} {{ candidacy.userInfos.firstName }}</div>
          <div class="content">Contenu: {{ candidacy.content }}</div>
          <div class="status">
            <div v-if="candidacy.status == 1" class="pending"> En attente </div>
            <div v-else-if="candidacy.status == 2" class="accepted"> Accepté </div>
            <div v-else class="refused"> Refusé </div>
          </div>
          <button v-if="candidacy.status == 1" @click="details(candidacy.id)">Détails</button>
        </div>
      </div>
    </div>
  </div>  
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue"
import { store } from "@/store";
import axios from "axios";
import router from "@/router";

let isLoading = ref(true)
let acceptedNumber = ref(0)
let maxCandidateNumber = ref(0)
const route = useRoute()

let candidacies = ref([])

onMounted(() => {
  getCandidacies()
  getMaxCandidateNumber()
})

async function getCandidacies() {
  await axios.get(process.env.VUE_APP_API_URL + "/candidacies/offer/" + route.params.offerId, {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    candidacies.value = resp.data
    isLoading.value = false
  })
  .catch(err => {
    console.log(err.response.status)
  })
}

function details(id) {
  router.push('/candidacy/' + id)
}

async function getMaxCandidateNumber() {
  await axios.get(process.env.VUE_APP_API_URL + "/candidacies/offer/" + route.params.offerId + "/numbers")
  .then(resp => {
    maxCandidateNumber.value = resp.data.maxCandidateNumber
    acceptedNumber.value = resp.data.acceptedNumber
    isLoading.value = false
  })
  .catch(err => {
    console.log(err.response.status)
  })
}

</script>

<style scoped lang="less">
  .main {
    color: black;
    .title {
      font-size: 20px;
      margin: 40px 0;
      font-weight: 600;
      text-align: center;
    }
    .candidacies {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;
      .candidacy {
        .content {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 10px;
        }
        .status {
          width: fit-content;
          .pending {
            background-color: orange;
          }
          .accepted {
            background-color: greenyellow;
          }
          .refused {
            background-color: red;
          }
        }
      }
    }
  }
</style>

<style scoped>
@media screen and (max-device-width: 1000px) {
  .modal {
    width: 60vw;
  }
}
@media screen and (max-device-width: 600px) {
  .modal {
    width: 80vw;
  }
}
</style>