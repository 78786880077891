<template>
  <section class="contact_wrapper">
    <div class="contact_info">
      <h3 class="Archive title">
        Information de contact
      </h3>

      <ul class="icons_wrapp">
        <li>
          <div class="icon">
            <span class="material-icons-outlined"> place </span>
          </div>
          <div class="text">
            Biopôle Clermont-Limagne, 6 Rue Emile Duclaux, 63360 Saint-Beauzire
          </div>
        </li>
        <li>
          <div class="icon">
            <span class="material-icons-outlined"> mail_outline </span>
          </div>
          <div class="text">
            contact@gapextra.com
          </div>
        </li>
        <li>
          <div class="icon">
            <span class="material-icons-outlined"> phone_in_talk </span>
          </div>
          <div class="text">
            (+33) 6 01 02 03 04
          </div>
        </li>
      </ul>

      <ul class="soci_wrap">
        <li>
          <a href="#">
            <span class="material-icons-outlined"> facebook </span>
          </a>
        </li>
        <li>
          <a href="#">
            <span class="material-icons-outlined"> face </span>
          </a>
        </li>
        <li>
          <a href="#">
            <span class="material-icons-outlined"> facebook </span>
          </a>
        </li>
        <li>
          <a href="#">
            <span class="material-icons-outlined"> face </span>
          </a>
        </li>
      </ul>
    </div>

    <div class="contact_msg">
      <h3 class="Archive title">
        Envoyer un message
      </h3>

      <div class="form_fild">
        <div class="input_group w_50">
          <input type="text" class="input" required />
          <label class="placeholder">Prénom</label>
        </div>

        <div class="input_group w_50">
          <input type="text" class="input" required />
          <label class="placeholder">Nom de famille</label>
        </div>

        <div class="input_group w_50">
          <input type="text" class="input" required />
          <label class="placeholder">Adresse e-mail</label>
        </div>

        <div class="input_group w_50">
          <input type="tel" class="input" required />
          <label class="placeholder">Numéro de portable</label>
        </div>

        <div class="input_group w_100">
          <textarea class="input input_textarea " rows="6" required></textarea>
          <label class="placeholder textarea">Ecrivez votre message ici...</label>
        </div>

        <div class="input_group">
          <input type="submit" class="btn" value="Envoyer " />
        </div>

      </div>

    </div>

  </section>
</template>

<script>

</script>

<style scoped lang="less">
@import "@/shared/theme.less";


@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v54/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
  format("woff2");
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: sans-serif;
  font-size: 14px;
  color: #222;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background: linear-gradient(90deg, @primaryColorLighter 50%, @primaryColor 50%);
  background-size: 100vw 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
@include size(md-device) {
  align-items: baseline;
}
@include size(sm-device) {
  align-items: baseline;
}
}

.contact_wrapper {
  margin: 15px auto;
  display: flex;
  // align-items: center;
  background: white;
  padding: 40px 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 800px;
  height: 500px;

@include size(md-device) {
  width: 80%;
  flex-direction: column;
}
@include size(sm-device) {
  width: 90%;
  flex-direction: column;
  padding-top: 0px;
}
  .contact_info {
    position: relative;
    width: 230px;
    flex: 0 0 230px;
    background: @primaryColor;
    color: #f6f6f6;
    margin-left: calc(-230px / 2 - 30px);
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  @include size(md-device) {
    width: 100%;
    flex: 0 0 100%;
    margin-left: -50px;
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  @include size(sm-device) {
    width: 100%;
    flex: 0 0 100%;
    margin-left: -25px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
    .title {
      font-size: 24px;
      margin: 0px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    .icons_wrapp {
      margin: 0px;
      padding: 0px;
      list-style: none;
      margin-bottom: 30px;
      li {
        display: flex;
        align-items: baseline;
        margin-bottom: 25px;
        .icon {
          .material-icons-outlined {
            vertical-align: middle;
            font-size: 24px;
            width: 32px;
          }
        }
        .text {
          vertical-align: middle;
          line-height: 1.4;
        }
      }
    }
    .soci_wrap {
      margin: 0px;
      padding: 0px;
      list-style: none;
      display: flex;
      position: absolute;
      bottom: 25px;
      li {
        margin: 0px 5px;
        a {
          color: #f4f4f4;
          text-decoration: none;
          transition: all 0.3s ease;
          &:hover {
            color: @primaryColorLighter; 
          }
          .material-icons-outlined {
            font-size: 32px;
          }
        }
      }
    }
  }

  .contact_msg {
    width: calc(100% - 230px / 2 - 55px);
    flex: 0 0 calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
  @include size(md-device) {
    width: calc(100% - 25px - 30px);
    flex: 0 0 calc(100% - 25px - 30px);
  }
  @include size(sm-device) {
    width: calc(100%);
    flex: 0 0 calc(100%);
    padding: 25px 0px;
  }
    .title {
      font-size: 24px;
      margin: 0px;
      font-weight: 400;
      margin-bottom: 30px;
      padding: 0 15px;
      color: @primaryColor;
    }
    .form_fild {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .input_group {
        position: relative;
        margin-bottom: 25px;
        padding: 0 15px;
        &.w_50 {
          width: calc(50% - 30px);
          flex: 0 0 calc(50% - 30px);
        @include size(sm-device) {
          width: calc(100% - 30px);
          flex: 0 0 calc(100% - 30px);
        }
        }
        &.w_100 {
          width: calc(100% - 30px);
          flex: 0 0 calc(100% - 30px);
        }

        .input {
          width: 100%;
          min-height: 30px;
          padding: 5px 0px;
          border: none;
          font-size: 18px;
          color: #444;
          outline: none;
          font-weight: 400;
          border-bottom: solid 2px @primaryColorLighter;
          &.input_textarea {
            min-width: 100%;
            min-height: 175px;
            max-height: 175px;
            max-width: 100%;
          }
          &:hover {
            border-bottom-color: @primaryColorLighter;
          }
          &:focus {
            border-bottom-color: #aaa;
          }
          &:valid,
          &:focus {
            ~ .placeholder {
              top: -50px;
              font-size: 12px !important;
              color: @primaryColor;
              &.textarea {
                top: -12px;
              }
            }
          }
        }

        .placeholder {
          position: absolute;
          left: 15px;
          top: 0px;
          bottom: 0px;
          display: flex;
          align-items: center;
          font-size: 18px;
          color: @primaryColorGrey;
          font-weight: 400;
          transition: all 0.3s ease;
          pointer-events: none;
          &.textarea {
            align-items: baseline;
          }
        }

        .btn {
          padding: 12px;
          border: none;
          border-radius: 0px;
          background-color: @primaryColor;
          color: white;
          min-width: 120px;
          outline: none;
          transition: all 0.3s ease;
          &:hover {
            background-color: #06423e;
          }
          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}


</style>