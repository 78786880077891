<template>
  <Teleport to="#modal">
    <Transition name="modal">
      <div class="modal-bg" v-if="isModalOpen">
        <div class="main" ref="modal">
          <div class="title">Voulez vous candidater à cette offre ?</div>
          <textarea v-model="application" placeholder="Votre candidature"></textarea>
          <div class="buttons">
            <button @click="applyToOffer()" class="yes">
              Candidater 
            </button>
            <button @click="no()" class="no"> Annuler </button>
          </div>
        </div>  
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { ref } from "vue"
import { onClickOutside } from "@vueuse/core";
import { store } from "@/store";
import axios from "axios";

const props = defineProps({
  isModalOpen: Boolean,
  offerId: String
})

const emit = defineEmits(["modalBoolean", "no"]);
const modal = ref(null);

let application = ref("")

onClickOutside(modal, () => {
  emit("modalBoolean");
});

function no() {
  emit("no")
}

async function applyToOffer() {
  const obj = {
    'content': application.value,
    'offerId': props.offerId
  }

  await axios.post(process.env.VUE_APP_API_URL + "/candidacies", JSON.stringify(obj), {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    console.log(resp.status)
    emit("no")
    application.value = ""
  })
  .catch(err => {
    console.log(err.response.status)
  })
}

</script>

<style scoped lang="less">
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(15, 19, 21, 0.8);
  backdrop-filter: blur(1rem);

  .main {
    position: absolute;
    width: 600px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: black;
    background-color: rgb(248, 249, 250);
    border-radius: 14px;

    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .title {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      text-align: center;
    }

    textarea {
      height: 40%;
      width: 80%;
      margin: 0 auto;
      outline: none;
    }

    .buttons {
      width: 100px;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      gap: 20px;
    }
  }
}

</style>

<style scoped>
@media screen and (max-device-width: 1000px) {
  .modal {
    width: 60vw;
  }
}
@media screen and (max-device-width: 600px) {
  .modal {
    width: 80vw;
  }
}
</style>