<template>
  <div class="main">
    <div v-if = isLoading>Loading...</div>
    <div class="candidacy">
      candidacy n°{{ candidacy.id }} <br> <br>
      Content: {{ candidacy.content }}
    </div>
    <div class="buttons">
      <button @click="patchCandidacy(2, candidacy.candidateId, candidacy.offerId)">J'accepte</button>
      <button @click="patchCandidacy(3, null, candidacy.offerId)">Je refuse</button>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { store } from "@/store";
import router from "@/router";
import axios from "axios";

const route = useRoute();

let isLoading = ref(true)
let candidacy = ref({})

onMounted(() => {
  getCandidacy()
})

async function getCandidacy() {
  isLoading.value = true
  await axios.get(process.env.VUE_APP_API_URL + "/candidacies/" + route.params.id, {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    candidacy.value = resp.data
    isLoading.value = false
  })
}

async function patchCandidacy(status, candidateId, offerId) {
  const obj = {
    'status': status,
    'offerId': offerId,
    'candidateId': candidateId
  }
  await axios.patch(process.env.VUE_APP_API_URL + "/candidacies/" + candidacy.value.id, JSON.stringify(obj), {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
  .then(resp => {
    console.log(resp.status)
    router.go(-1)
  })
  .catch(error => {
    console.log(error.response.status)
  })
}

</script>

<style scoped lang="less">
.main {
  margin-top: 50px;
  .buttons {
    margin-top: 20px;
  }
}
</style>