<template>
  <section class="presentation">
    <div class="container">
      <div class="text-container">
        <h2 class="Archive">Notre vision et mission</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
      </div>
      <div class="image-container">
        <img src="https://img.freepik.com/vecteurs-libre/papier-peint-geometrique-design-graphique_52683-34399.jpg?w=2000" :alt="imageAlt" />
      </div>
    </div>

    <div class="container">
      <div class="image-container">
        <img src="https://img.freepik.com/vecteurs-libre/site-web-developpement-web-ingenierie-programmation-codage-ecrans-interface-realite-augmentee-logiciel-programmation-ingenieur-projet-developpeur-conception-application-illustration-dessin-anime_107791-3863.jpg" :alt="imageAlt" />
      </div>
      <div class="text-container">
        <h2 class="Archive">Notre histoire</h2>
        <p>Ideoque fertur neminem aliquando ob haec vel similia poenae addictum oblato de more elogio revocari iussisse, quod inexorabiles quoque principes factitarunt. et exitiale hoc vitium, quod in aliis non numquam intepescit, in illo aetatis progressu effervescebat, obstinatum eius propositum accendente adulatorum cohorte. Emensis itaque difficultatibus multis et nive obrutis callibus plurimis ubi prope Rauracum ventum est ad supercilia fluminis Rheni, resistente multitudine Alamanna pontem suspendere navium conpage Romani.</p>
      </div>
    </div>

  </section>
</template>

<script>
</script>

<style scoped>

.presentation {
  margin: 150px 150px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 50px;
}

.image-container {
  width: 50%;
  text-align: center;
}

.image-container .img {
  width: 75%;
}

.text-container {
  margin: 40px;
  width: 50%;
  padding-left: 50px;
  padding-right: 50px;
  /*padding: 20px 20px;*/
}

.text-container:hover {
  box-shadow: -10px 0px 20px 0 rgba(0,0,0,0.3);
  transform: translateY(-10px);
}


img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.2);
}

img:hover {

  box-shadow: -10px 0px 20px 0 rgba(0,0,0,0.3);
  transform: translateY(-10px);
}
</style>