<template>
 <section class="searchBar">
    <div class="searchLigne">
      <div class="field">
        <input type="text" placeholder="Que cherchez vous ?" required>
      </div>

      <div class="field">
        <input type="text" placeholder="Indiquer votre localisation" required>
      </div>

      <div class="field">
        <input type="text" placeholder="Indiquer votre localisation" required>
      </div>

      <div class="icon" >
        <a href="#"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="padding-top: 25%;color: white"/></a>
      </div>
    </div>

 </section>
</template>

<script>
export default {
  name: "DocVierge"
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.searchBar {
  position: relative;
  margin-right: 20%;
  margin-left: 20%;
  font-family: 'Roboto', sans-serif;
}

.searchLigne {
  display: flex;
  margin-right: 40px;
  justify-content: center;
}

.field{
  height: 50px;
  margin-top: 20px;
  margin-left: 40px;
}

.field input{
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.field input:focus{
  border-color: @primaryColor;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.field input::placeholder{
  color: #999;
  transition: all 0.3s ease;
}
.field input:focus::placeholder{
  color: @primaryColor;
}

.searchLigne .icon {
  background-color: @primaryColor;

  border-radius: 30px;
  font-size: 25px;
  text-align: center;
  width: 50px;
  height: 50px;
  margin-left: 40px;
  transform: translateY(40%); /* tiré de la moitié de sa propre hauteur */
}

.searchLigne .icon:hover {
  background-color: @primaryColorLighter;
}

</style>