<template>
  <div id="app">
    <ul>
      <input type="text" v-model="inputFilter" />
      <li v-for="article in filteredArticles" v-bind:key="article">{{ article }}</li>
    </ul>
  </div>

  <TestMaps/>

</template>

<script setup>

import TestMaps from "@/views/Test/TestMapsAuto.vue";

</script>

<script>
export default {
  name: 'App',
  data() {
    return {
      articles: [
        'Chaise de salle à manger',
        'Chaise de bureau',
        'Bureau',
        'Ordinateur',
        'Meuble TV'
      ],
      inputFilter: ''
    }
  },
  computed: {
    filteredArticles() {
      return this.articles.filter((element) => {
        return element.match(this.inputFilter);
      });
    }
  }
}
</script>