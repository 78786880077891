<template>
  <section class="log">
    <div class="wrapper">
      <div class="title-text">
        <div v-if="signIn === true" class="Archive title login">Se connecter</div>
        <div v-if="signIn === false" class="Archive title signup">S'inscrire</div>
      </div>
      <div class="form-container">
        <div class="slide-controls">
          <input @click="signIn = true" type="radio" name="slide" id="login" checked>
          <input @click="signIn = false" type="radio" name="slide" id="signup">
          <label for="login" class="slide login">Se connecter</label>
          <label for="signup" class="slide signup">S'inscrire</label>
          <div class="slider-tab"></div>
        </div>
        <div class="form-inner">
          <form v-if="signIn === true" action="#" class="login">

            <div v-if="inConnection" class="asyncConnexion">
              <Spinner color="black"/>
            </div>

            <div v-if="badAuth" class="badAuth">
              <h2 class="Archive">Attention la connexion a échoué</h2>
              <h4>Veuillez verifier vos informations d'identification</h4>
            </div>

            <div class="field">
              <input @keyup.enter="submit_login" v-model="email" type="text" placeholder="Adresse e-mail" required>
            </div>
            <div class="field">
              <input @keyup.enter="submit_login" v-model="password" type="password" placeholder="Mot de passe" required>
            </div>
            <br>
            <div class="btnCheckBox">
              <p class="btnCheckBoxText">Rester connecter ?</p>
              <input type="checkbox" id="switch" /><label class="toggleBTN" for="switch">Rester connecter ?</label>
            </div>
            <br>
            <div class="pass-link"><a href="#">Mot de passe oublié?</a></div>
            <div class="field btn">
              <div class="btn-layer"></div>
              <input @click="submit_login" v-if="!connected && !inConnection" type="submit" value="Se connecter">
              <input v-if="inConnection" type="submit" value='Vérification des identifiants'>
              <input v-if="connected" type="submit" value="COnnexion reussit ! Redirection automatique !">
            </div>
            <div class="connectOther">

              <div class="imgConnect">
                <a href="#"><img class="icon-social" src="../../../assets/icons/google_50px.png"></a>
              </div>
              <div class="imgConnect">
                <a href="#"><img class="icon-social" src="../../../assets/icons/twitter_logo_50px.png"></a>
              </div>
              <div class="imgConnect">
                <a href="#"><img class="icon-social" src="../../../assets/icons/linkedin_logo_50px.png"></a>
              </div>
              <div class="imgConnect">
                <a href="#"><img class="icon-social" src="../../../assets/icons/facebook_logo_50px.png"></a>
              </div>


            </div>
            <!--          TODO: Rajouter la possiblité de ce connecter avec Google/Facebook-->
          </form>

          <form v-if="signIn === false" action="#" class="signup">

            <div v-if="inCreation" class="asyncConnexion">
              <Spinner color="black"/>
            </div>

            <div v-if="etapeSignUp === 0" class="Etape1-SignUp">
              <div class="titleSignUp">
                <h2 class="Archive">Inscription :</h2>
                <h4>Etape 1 : Information de contact</h4>
              </div>
              <div class="field">
                <input v-model="user.lastName" style="width: 48%;" type="text" placeholder="Nom" required>
                <input v-model="user.firstName" style="width: 48%;margin-left: 4%;" type="text" placeholder="Prenom" required>
              </div>
              <div class="field">
                <input v-model="user.phoneNumber" style="width: 78%;" type="text" placeholder="Numero de teléphone" required>
                <input v-model="user.age" style="width: 18%;margin-left: 4%;" type="number" placeholder="Votre age" required>
              </div>
              <div class="field">
                <input v-model="user.email" type="text" placeholder="Adresse e-mail" required>
              </div>
              <div class="field">
                <input v-model="user.password" type="password" placeholder="Mot de passe" required>
              </div>
              <div class="field">
                <input type="password" placeholder="Confirmez le mot de passe" required>
              </div>
              <div class="slide-controls">
                <input @click="candidat = false" v-model="user.type" value="employer" type="radio" name="slide" id="inputEntreprise">
                <input @click="candidat = true" v-model="user.type" value="candidate"  type="radio" name="slide" id="inputParticulier" checked>
                <label for="inputEntreprise" class="slide inputEntreprise">Je suis une entreprise</label>
                <label for="inputParticulier" class="slide inputParticulier">Je suis un particulier</label>
                <div class="slider-tab"></div>
              </div>
              <br>
              <div class="field btn">
                <div class="btn-layer"></div>
                <input @click="etapeSignUp=1" type="submit" value="Commencer l'inscription en 5min !">
              </div>
            </div>

            <div v-if="etapeSignUp === 1" class="Etape2-SignUp">
              <div v-if="candidat === true" class="E2-Particulier">
                <div class="titleSignUp">
                  <h2 class="Archive">Inscription : Particulier</h2>
                  <h4>Etape 2 : Information de localisation</h4>
                </div>

                <div class="field">
                  <input v-model="user.address" type="text" placeholder="Adresse de votre domicile" required>
                </div>

                <div class="field">
                  <input style="width: 56%;" type="text" placeholder="Commune de votre domicile" required>
                  <input v-model="user.zipCode" style="width: 40%;margin-left: 4%;" type="text" placeholder="Code postal" required>
                </div>

                <div class="field">
                  <input v-model="user.region" type="text" placeholder="Region de votre domicile" required>
                </div>
                <br>
                <div class="pass-link"><a @click="etapeSignUp=0">Recommencer ?</a></div>
                <br>
                <div class="field btn">
                  <div class="btn-layer"></div>
                  <input @click="etapeSignUp=2" type="submit" value="S'inscrire Etape 2/3">
                </div>
              </div>

              <div v-else class="E2-Entreprise">
                <div class="titleSignUp">
                  <h2 class="Archive">Inscription : Entreprise</h2>
                  <h4>Etape 2 : Information de l'entreprise</h4>
                </div>

                <div class="field">
                  <input type="text" placeholder="Nom de l'entreprise" required>
                </div>

                <div class="field" style="text-align: left">
                  <input style="width: 75%;" type="text" placeholder="Numero SIREN" required>
                </div>

                <div class="field">
                  <input style="width: 66%;" type="text" placeholder="Votre poste dans l'entreprise" required>
                  <input style="width: 30%;margin-left: 4%;" type="text" placeholder="Secteur d'activité" required>
                </div>

                <div class="field">
                  <input v-model="user.address" type="text" placeholder="Adresse de l'entreprise" required>
                </div>

                <div class="field">
                  <input style="width: 56%;" type="text" placeholder="Commune de l'entreprise" required>
                  <input v-model="user.zipCode" style="width: 40%;margin-left: 4%;" type="text" placeholder="Code postal de l'entreprise" required>
                </div>

                <div class="field">
                  <input v-model="user.region" type="text" placeholder="Region de l'entreprise" required>
                </div>

                <br>
                <div class="pass-link"><a @click="etapeSignUp=0">Recommencer ?</a></div>
                <br>
                <div class="field btn">
                  <div class="btn-layer"></div>
                  <input @click="submit_logup" v-if="!created && !inCreation" type="submit" value="S'inscrire Etape 2/2">
                  <input v-if="inCreation" type="submit" value='Vérification des informations'>
                  <input v-if="created" type="submit" value="Inscription reussit ! Redirection automatique !">
                </div>
              </div>

            </div>

            <div v-if="etapeSignUp === 2" class="Etape3-SignUp">
              <div v-if="candidat === true" class="E3-Particulier">
                <div class="titleSignUp">
                  <h2 class="Archive">Inscription : Particulier</h2>
                  <h4>Etape 3 : Information banquaire</h4>
                </div>


                <div class="field">
                  <input style="width: 66%;" type="text" placeholder="Indiquer votre IBAN" required>
                  <input style="width: 30%;margin-left: 4%;" type="text" placeholder="Indiquer votre B.I.C" required>
                </div>

                <br>
                <div class="pass-link"><a @click="etapeSignUp=0">Recommencer ?</a></div>
                <br>
                <div class="field btn">
                  <div class="btn-layer"></div>
                  <input @click="submit_logup" v-if="!created && !inCreation" type="submit" value="S'inscrire Etape 3/3">
                  <input v-if="inCreation" type="submit" value='Vérification des informations'>
                  <input v-if="created" type="submit" value="Inscription reussit ! Redirection automatique !">
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {
      signIn: true,
      candidat: true,
      etapeSignUp: 0,
    }
  }
}
</script>

<script setup>
import { ref } from "vue"
import axios from "axios";
import Spinner from "@/components/Spinner.vue"
import { store } from "@/store";
import router from "@/router";
import parseJWT from '@/utils/parseJWT'

// eslint-disable-next-line no-undef
const emit = defineEmits(["signinWorked"]);

const lineColor = ref("rgb(37, 212, 108)");

let connected = ref(false);
let inConnection = ref(false);
let badAuth = ref(false);

let email = ref("");
let password = ref("");

let created = ref(false);
let inCreation = ref(false);
let conflict = ref(false);

let user = {
  rib: {}
};

async function submit_logup() {
  inCreation.value = true;

  await axios.post(process.env.VUE_APP_API_URL + "/signup", JSON.stringify(user))
      .then(resp => {
        if (resp.status === 201) {
          inCreation.value = false;
          conflict.value = false;
          created.value = true;

          setTimeout(() => {
            created.value = false;
            router.push('/Welcome')
          }, 2000)
        }
      })
      .catch(error => {
        if (error.response.status === 409) {
          inCreation.value = false;
          conflict.value = true;
          lineColor.value = "orange";
          setTimeout(() => {
            conflict.value = false;
          }, 3000)
        }
      })
}

async function submit_login() {
  inConnection.value = true;
  const obj = {
    "email": email.value,
    "password": password.value
  };
  await axios.post(process.env.VUE_APP_API_URL + "/signin", JSON.stringify(obj))
      .then(resp => {
        if (resp.status == 200) {
          inConnection.value = false;
          connected.value = true;

          const parsedJWT = parseJWT(resp.data);
          localStorage.setItem("token", resp.data);
          store.token = resp.data
          store.userName = parsedJWT.lastName + " " + parsedJWT.firstName
          store.userId = parsedJWT.userId
          store.userType = parsedJWT.userType

          connected.value = true;
          password.value = "";

          setTimeout(() => {
            connected.value = false;
            router.push('/')
          }, 500)
        }
      })
      .catch(() => {
        inConnection.value = false;
        connected.value = false;
        badAuth.value = true;
        lineColor.value = "orange";
        setTimeout(() => {
          badAuth.value = false;
        }, 3000)
      })
}
</script>

<style scoped lang="less">
@import "@/shared/theme.less";
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

.asyncConnexion{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.badAuth {
  text-align: center;
  color: @secondaryColor;
  padding-top: 20px;
  padding-bottom: 20px;
}

.connectOther {
  text-align: center;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-left: 35px;
}

.imgConnect {
  padding-right: 35px;
}

.icon-social {
  //background-color: #2F3242;
  //border: solid;
  //border-radius: 30px;
}

.titleSignUp {
  padding-top: 15px;
  text-align: left;
}

.btnCheckBox {
  display: flex;
}

.btnCheckBoxText {
  margin-right: 15px;
  margin-top: 5px;
  font-family: "Roboto",serif;
  font-size: 25px;
  color: @primaryColorGrey;
}

input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggleBTN {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 40px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.toggleBTN:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + .toggleBTN {
  background: @primaryColor;
}

input:checked + .toggleBTN:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.toggleBTN:active:after {
  width: 70px;
}


.log {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
html,body{
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  //background: -webkit-linear-gradient(left, #003366,#004080,#0059b3
  //, #0073e6);
  background: linear-gradient(60deg, @primaryColor 0%, rgba(0,172,193,1) 100%);

}
::selection{
  background: #1a75ff;
  color: #fff;
}
.wrapper{
  overflow: hidden;
  width: 700px;
  max-width: 750px;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
}
.wrapper .title-text{
  display: flex;
  width: 200%;
}
.wrapper .title{
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.wrapper .slide-controls{
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}
.slide-controls .slide{
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup{
  color: #000;
}
.slide-controls .slider-tab{
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  //background: -webkit-linear-gradient(left,#003366,#004080,#0059b3
  //, #0073e6);
  background: -webkit-linear-gradient(right,@primaryColorLighter,@primaryColor, @primaryColorLighter);

  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}

.doubleColForm {
  width: 47%;
}

input[type="radio"]{
  display: none;
}

#signup:checked ~ .slider-tab{
  left: 50%;
}
#signup:checked ~ label.signup{
  color: #fff;
  cursor: default;
  user-select: none;
}
#signup:checked ~ label.login{
  color: #000;
}
#login:checked ~ label.signup{
  color: #000;
}
#login:checked ~ label.login{
  cursor: default;
  user-select: none;
}




#inputParticulier:checked ~ .slider-tab{
  left: 50%;
}
#inputParticulier:checked ~ label.inputParticulier{
  color: #fff;
  cursor: default;
  user-select: none;
}
#inputParticulier:checked ~ label.inputEntreprise{
  color: #000;
}
#inputEntreprise:checked ~ label.inputParticulier{
  color: #000;
}
#inputEntreprise:checked ~ label.inputEntreprise{
  cursor: default;
  user-select: none;
}





.wrapper .form-container{
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner{
  display: flex;
  width: 200%;
}
.form-container .form-inner form{
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.form-inner form .field{
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input{
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.form-inner form .field input:focus{
  border-color: @primaryColor;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder{
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder{
  color: @primaryColor;
}
.form-inner form .pass-link{
  margin-top: 5px;
}
.form-inner form .signup-link{
  text-align: center;
  margin-top: 30px;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a{
  color: @primaryColor;
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover{
  text-decoration: underline;
}


form .btn{
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
form .btn .btn-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right,@primaryColorLighter,@primaryColor, @primaryColorLighter);
  border-radius: 15px;
  transition: all 0.4s ease;;
}
form .btn:hover .btn-layer{
  left: 0;
}
form .btn input[type="submit"]{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

</style>