<template>
<!--  TODO : RESOLV BUG __name-->
  <div v-if="$route.name.__name !== 'Home'">
    <Header/>
    <NavBar @trigger-navbar="changeRouterView()"/>
  </div>
  <div id="router" class="router">
    <router-view/>
  </div>
  <p style="padding-bottom: 5em;"></p>
  <Footers/>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue"
import Footers from "@/components/Footer-all.vue"
import Header from "@/components/Header.vue";

function changeRouterView() {
  if (document.getElementById("router").classList.contains("disable")) {
    document.getElementById("router").classList.remove("disable");
  } else {
    setTimeout(() => {
      document.getElementById("router").classList.add("disable");
    }, 350);
  }
}
</script>

<style lang="less">
@import "@/shared/theme.less";

body {
  background-color: @bgColorLight;
}

#app {
  font-family: @textFontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: @bgColorLight;
}
@media screen and (max-device-width: 480px) {
  .router.disable {
    display: none;
  }
}
</style>