<template>
  <!-- ********************* newsletter starts ****************************** -->

  <section class="newsletter" id="newsletterdiv">
    <div class="container headings text-center">
      <h1 class="Arcchive text-center font-weight-bold">ABONNEZ-VOUS À NOTRE NEWSLETTER</h1>
      <p>Vous pourrez vous désabonner dans votre profil.</p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 col-12 ">
          <div class="input-group mb-3">
            <input type="text" class="form-control newsletter-input" placeholder="Indique ton email ici !">
            <div class="input-group-append">
              <span class="input-group-text">S'abonner</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ********************* newsletter ends ****************************** -->
</template>

<script>

</script>

<style scoped lang="less">

@import "@/shared/theme.less";

/* ********************* newsletter starts ****************************** */

.newsletter{
  width: 100%;
  height: auto;
  margin: 80px 0;
}
.newsletter-input{
  border-radius: 100px 0px 0px 100px!important;
  min-width: 150px;
  min-height: 45px;
}
.input-group-text{
  color: #fff!important;
  background: @primaryColorLighter!important;
  border-radius: 0 100px 100px 0!important;
  min-width: 130px;
  min-height: 45px;
}
.input-group-text:hover{
  cursor: pointer;
  opacity: 0.8;
}

/* *********************  newsletter ends ****************************** */
</style>