<template>
  <div class="modal-bg">
    <div class="main" ref="modal">
      <div v-if = isLoading>
        <h1>loading...</h1>
      </div>
      <div v-else>
        <div class="title">Candidatures {{ acceptedNumber }} / {{ maxCandidateNumber }}</div>
        <div class="candidacies">
          <div class="candidacy" v-for="(candidacy, index) in candidacies" :key="index">
            <div class="user">Utilisateur: {{ candidacy.candidateId }}</div>
            <div class="content">Contenu: {{ candidacy.content }}</div>
            <div class="status">
              <div v-if="candidacy.status == 1" class="pending"> En attente </div>
              <div v-else-if="candidacy.status == 2" class="accepted"> Accepté </div>
              <div v-else class="refused"> Refusé </div>
            </div>
            <button @click="details(candidacy.id)">Détails</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue"
import { onClickOutside } from "@vueuse/core";
import { store } from "@/store";
import axios from "axios";
import router from "@/router";

let isLoading = ref(true)
let acceptedNumber = ref(0)
let maxCandidateNumber = ref(0)
const route = useRoute()
const modal = ref(null)

let candidacies = ref([])

onMounted(() => {
  getCandidacies()
  getMaxCandidateNumber()
})

onClickOutside(modal, () => {
  router.go(-1)
});

async function getCandidacies() {
  await axios.get(process.env.VUE_APP_API_URL + "/candidacies/offer/" + route.params.offerId, {
    headers: {
      'Authorization': `Bearer ${store.token}`
    }
  })
      .then(resp => {
        candidacies.value = resp.data
        isLoading.value = false
      })
      .catch(err => {
        console.log(err.response.status)
      })
}

function details(id) {
  router.push('/candidacy/' + id)
}

async function getMaxCandidateNumber() {
  await axios.get(process.env.VUE_APP_API_URL + "/candidacies/offer/" + route.params.offerId + "/numbers")
      .then(resp => {
        maxCandidateNumber.value = resp.data.maxCandidateNumber
        acceptedNumber.value = resp.data.acceptedNumber
        isLoading.value = false
      })
      .catch(err => {
        console.log(err.response.status)
      })
}

</script>

<style scoped lang="less">

</style>