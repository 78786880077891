import { reactive } from 'vue'
import parseJWT from '@/utils/parseJWT'
import router from '@/router'

var token = null

var userName = null
var userId = null
var userType = null

init()

function init() {
  if (localStorage.token != null) {
    const parsedJWT = parseJWT(localStorage.token);
    if (parsedJWT.exp < Date.now()/1000) {
      localStorage.removeItem("token");
      router.push('/connexion')
    } else {
      token = localStorage.token
      userName = parsedJWT.lastName + " " + parsedJWT.firstName
      userId = parsedJWT.userId
      userType = parsedJWT.userType
    }
  }
}

export const store = reactive({
  token: token,
  userName: userName,
  userId: userId,
  userType: userType
})