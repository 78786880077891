<template>
  <section class="Header">
    <div class="intro">
      <div class="title">
        Presentation du Concept
      </div>
      <div class="texte-intro">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
      </div>
    </div>
  </section>
</template>

<script setup>

</script>

<style scoped lang="less">
@import "@/shared/theme.less";

.title {
  .titlePageStyle();
}

.texte-intro {
  position: relative;
  bottom: 30px;
}

</style>